/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, Suspense, lazy } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

// // Argon Dashboard 2 PRO MUI example components
// import Sidenav from 'Routes/Organizer/Dashboard/Sidenav';
// import Configurator from 'examples/Configurator';

// Argon Dashboard 2 PRO MUI themes
import theme from 'assets/theme';
import themeRTL from 'assets/theme/theme-rtl';
import themeDark from 'assets/theme-dark';
import themeDarkRTL from 'assets/theme-dark/theme-rtl';

// My own CSS additions
import 'assets/css/custom.module.css';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// Argon Dashboard 2 PRO MUI routes
import routes from 'routes';

import { SnackbarProvider } from 'notistack';

// Argon Dashboard 2 PRO MUI contexts
import {
  useArgonController,
  setMiniSidenav,
  setOpenConfigurator
} from 'context';

// Images
// import brand from 'assets/images/logos/logo_with_text_two_rows_white.png';
// import brandDark from 'assets/images/logos/logo_with_text_two_rows_blue.png';

// Icon Fonts
import 'assets/css/nucleo-icons.css';
import 'assets/css/nucleo-svg.css';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink
} from '@apollo/client';

import UserPool from 'services/cognito/userPool';
import * as Realm from 'realm-web';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { getSession } from 'services/cognito/cognitoAccount';
import { PuffLoader } from 'react-spinners';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Loader from 'Loader';
import CheckoutPage from 'Routes/LandingPage/ShoppingCart/checkout';

//Indivdual routes

const LoginPage = lazy(() => import('Routes/Login'));
const LogoutPage = lazy(() => import('Routes/Logout'));
const SignupPage = lazy(() => import('Routes/SignUp'));

const Imprint = lazy(() => import('Routes/Legal/Imprint'));
const Privacy = lazy(() => import('Routes/Legal/Privacy'));

const AthleteProfile = lazy(() => import('Routes/Athlete/Profile'));
const AthleteRegistrations = lazy(() => import('Routes/Athlete/Registrations'));
const AthleteOrders = lazy(() => import('Routes/Athlete/Orders'));
const AthleteDashboard = lazy(() => import('Routes/Athlete/Dashboard'));

const LandingPage = lazy(() => import('Routes/LandingPage'));
// const EventPage = lazy(() => import('Routes/LandingPage/Events/Details'));
const EventInstancePage = lazy(() =>
  import('Routes/LandingPage/EventInstances/Details')
);

const Leaderboard = lazy(() => import('Routes/Leaderboard'));

const Parallax = lazy(() => import('Routes/LandingPage/ParallaxHeroSection'));
const ParallaxHeroSection = lazy(() =>
  import('Routes/LandingPage/ParallaxHeroSection/index7')
);
const PaneOfGlass = lazy(() =>
  import('Routes/LandingPage/ParallaxHeroSection/index6_pane_of_glass')
);

const CreateOrganization = lazy(() =>
  import('Routes/LandingPage/CreateOrganization')
);

const EventRegistration = lazy(() =>
  import('Routes/LandingPage/EventRegistration')
);

const AddNewEventRegistration = lazy(() =>
  import('Routes/LandingPage/EventRegistration/AddNewRegistration')
);

const ManageEventRegistration = lazy(() =>
  import('Routes/LandingPage/EventRegistration/ManageExistingRegistration')
);

const EventRegistrationPayment = lazy(() =>
  import('Routes/LandingPage/EventRegistration/paymentForm')
);
const EventRegistrationPaymentSuccess = lazy(() =>
  import('Routes/LandingPage/EventRegistration/paymentForm')
);

const OrganizerDashboard = lazy(() => import('Routes/Organizer/Dashboard'));
const OrganizerEventCreate = lazy(() =>
  import('Routes/Organizer/Events/Create')
);
const OrganizerEventsList = lazy(() => import('Routes/Organizer/Events/List'));
const OrganizerEventDetails = lazy(() =>
  import('Routes/Organizer/Events/Details')
);
const OrganizerEventInstanceSetup = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Setup')
);
const OrganizerEventInstanceParticipants = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Participants')
);
const OrganizerEventInstanceWorkouts = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Workouts')
);
const OrganizerEventInstanceJudging = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Judging')
);
const OrganizerEventInstanceLeaderboard = lazy(() =>
  import('Routes/Organizer/Events/Details/EventInstances/Leaderboard')
);

const OrganizerOrganizationEditor = lazy(() =>
  import('Routes/Organizer/Organization')
);

const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;

const realm = new Realm.App(REALM_APP_ID);

if (process.env.REACT_APP_ENV === 'PROD') {
  // disable React Dev Tools for production
  disableReactDevTools();

  // deactivate  console logging on PROD
  console.log = () => {};
}

const tdu = `\n\n████████╗██████╗ ██╗   ██╗
╚══██╔══╝██╔══██╗██║   ██║
   ██║   ██║  ██║██║   ██║
   ██║   ██║  ██║██║   ██║
   ██║   ██████╔╝╚██████╔╝
   ╚═╝   ╚═════╝  ╚═════╝ \n\n`;

console.log(tdu);

// Gets a valid Realm user access token to authenticate requests
async function getValidAccessToken() {
  // Guarantee that there's a logged in user with a valid access token

  // !ATTENTION, when creating a new REALM instance and / or Cognito User Pools, also change AUD requirement in JWT Auth configuration to new / prod cognito pool id's. Otherwise JWT login to REALM will fail.

  const getIdToken = async () =>
    new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            console.error("Error fetching user's id-token", err);
            reject(err);
          } else {
            console.log('Fetched ID-Token:', session.idToken.jwtToken);
            resolve(session.idToken.jwtToken);
          }
        });
      } else {
        console.log('User currently not logged in to Cognito User Pool.');
        reject('No user logged in.');
      }
    });

  const logInToRealm = async () => {
    console.log('Realm: Logging in User');
    const idToken = await getIdToken().catch((error) =>
      console.error('ERROR getting Access Token from AWS Cognito:\n', error)
    );

    if (idToken) {
      await realm.logIn(Realm.Credentials.jwt(idToken));
      // console.log(
      //   'REALM: Logged into Mongo Realm with User ID Token from Cognito.',
      //   idToken
      // );
    } else {
      console.log(
        'No ID-Token available, logging in to REALM as anonymous user with limited privileges.'
      );
      await realm.logIn(Realm.Credentials.anonymous());
    }
    console.log('App.js: Realm: User logged in:', realm.currentUser);
  };

  // if (app.currentUser)
  //   console.log(
  //     'App.js: Realm: Already logged in, current user:',
  //     app.currentUser.isLoggedIn
  //   );

  if (realm.currentUser) {
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.
    console.log('App.js: Realm: User logged in:', realm.currentUser);
    // console.log('Using existing REALM login');
    await realm.currentUser.refreshCustomData().catch(async (err) => {
      await logInToRealm().catch((error) =>
        console.error('ERROR logging in to REALM:\n', error)
      );
    });
  } else {
    await logInToRealm();
  }

  return realm.currentUser.accessToken;
}

// Configure the ApolloClient to connect to your app's GraphQL endpoint
const newAuthenticatedApolloClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: `https://eu-central-1.aws.realm.mongodb.com/api/client/v2.0/app/${REALM_APP_ID}/graphql`,
      // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
      // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
      // access token before sending the request.
      fetch: async (uri, options) => {
        const accessToken = await getValidAccessToken();
        options.headers.Authorization = `Bearer ${accessToken}`;
        return fetch(uri, options);
      }
    }),
    cache: new InMemoryCache()
  });
};

const client = newAuthenticatedApolloClient();

export default function App() {
  //   const Styles = makeStyles((theme) => ({
  //     variantError: {
  //       backgroundColor: theme.palette.error.main,
  //     },
  //     variantSuccess: {
  //       backgroundColor: theme.palette.success.main,
  //       // color: "#343434",
  //     },
  //   }));

  //   const classes = Styles();
  //   // const classes = {};

  const [controller, dispatch] = useArgonController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    darkSidenav,
    darkMode
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState('loading');

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin]
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <ArgonBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={handleConfiguratorOpen}>
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </ArgonBox>
  );

  // if not logged in, only open route to login page.

  useEffect(() => {
    getSession()
      .then((session) => {
        console.log('Session: ', session);
        setIsLoggedIn(true);
      })
      .catch((err) => {
        setIsLoggedIn(false);
      });
  }, []);

  // if (!isLoggedIn) {
  //   return (
  //     <>
  //       <ApolloProvider client={client}>
  //         <CacheProvider value={rtlCache}>
  //           <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
  //             <SnackbarProvider
  //               maxSnack={3}
  //               // classes={{
  //               // variantError: classes.variantError,
  //               // variantSuccess: classes.variantSuccess,
  //               // }}
  //             >
  //               <CssBaseline />
  //               <Routes>
  //                 <Route path='/' element={<LandingPage />} />
  //                 <Route path='/signup' element={<SignupPage />} />
  //                 <Route path='/login' element={<LoginPage />} />
  //                 <Route path='/logout' element={<LogoutPage />} />
  //                 <Route path='*' element={<Navigate to='/' redirect />} />
  //               </Routes>
  //               {/* <LoginPage /> */}
  //               {/* <Navigate replace to="/login" /> */}
  //             </SnackbarProvider>
  //           </ThemeProvider>
  //         </CacheProvider>
  //       </ApolloProvider>
  //     </>
  //   );
  // }

  if (isLoggedIn === 'loading') {
    <Loader />;
  }

  // if (isLoggedIn === true)
  // return direction === 'rtl' ? (
  //   <ApolloProvider client={client}>
  //     <CacheProvider value={rtlCache}>
  //       <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
  //         <SnackbarProvider
  //           maxSnack={3}
  //           classes={
  //             {
  //               // variantError: classes.variantError,
  //               // variantSuccess: classes.variantSuccess,
  //             }
  //           }
  //         >
  //           <CssBaseline />
  //           {layout === 'dashboard' && (
  //             <>
  //               <Sidenav
  //                 color={sidenavColor}
  //                 brand={darkSidenav || darkMode ? brand : brandDark}
  //                 brandName=''
  //                 routes={routes}
  //                 onMouseEnter={handleOnMouseEnter}
  //                 onMouseLeave={handleOnMouseLeave}
  //               />
  //               <Configurator />
  //               {configsButton}
  //             </>
  //           )}
  //           {layout === 'vr' && <Configurator />}
  //           <Routes>
  //             {getRoutes(routes)}
  //             <Route path='/' element={<LandingPage />} />
  //             <Route path='/Organizer/events/:id' element={<EventDetails />} />
  //             <Route path='/login' element={<LoginPage />} />
  //             <Route path='/logout' element={<LogoutPage />} />
  //             <Route path='/signup' element={<SignupPage />} />
  //             <Route path='/athlete/profile' element={<AthleteProfile />} />
  //             <Route path='/events/:id' element={<EventPage />} />
  //             <Route
  //               path='/events/:id/registration'
  //               element={<EventRegistration />}
  //             />
  //             <Route
  //               path='/events/:id/registration/:registrationId'
  //               element={<EventRegistration />}
  //             />
  //             <Route
  //               path='/events/:id/registration/:registrationId/payment'
  //               element={<EventRegistrationPayment />}
  //             />
  //             <Route
  //               path='/events/:id/registration/:registrationId/payment-success'
  //               element={<EventRegistrationPaymentSuccess />}
  //             />
  //             <Route path='*' element={<Navigate to='/' />} />
  //           </Routes>
  //         </SnackbarProvider>
  //       </ThemeProvider>
  //     </CacheProvider>
  //   </ApolloProvider>
  // ) : (
  return (
    <>
      <Suspense
        fallback={
          <>
            <ThemeProvider theme={darkMode ? themeDark : theme}>
              <Loader />
            </ThemeProvider>
          </>
        }>
        <ApolloProvider client={client}>
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <SnackbarProvider
              maxSnack={3}
              classes={
                {
                  // variantError: classes.variantError,
                  // variantSuccess: classes.variantSuccess,
                }
              }>
              <CssBaseline />

              {/* {layout === 'dashboard' && (
                <>
                  <Sidenav
                    color={sidenavColor}
                    brand={darkSidenav || darkMode ? brand : brandDark}
                    brandName='Argon Dashboard 2 PRO'
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />
                  <Configurator />
                  {configsButton}
                </>
              )}
              {layout === 'vr' && <Configurator />} */}
              {/* <ArgonBox display='flex' flexDirection='column' minHeight='100vh'> */}
              <Routes>
                {/* {getRoutes(routes)} */}
                {/* <Route path="/" element={<LandingPage />} /> */}
                <Route path="/" element={<ParallaxHeroSection />} />

                <Route path="/login" element={<LoginPage />} />
                <Route path="/logout" element={<LogoutPage />} />
                <Route path="/signup" element={<SignupPage />} />

                <Route path="/legal/imprint" element={<Imprint />} />
                <Route path="/legal/privacy" element={<Privacy />} />

                {/* ###### LANDING PAGE ##################################################### */}
                <Route
                  path="/create-organization"
                  element={<CreateOrganization />}
                />
                <Route
                  path="/create-organisation"
                  element={<CreateOrganization />}
                />
                <Route path="/parallax" element={<ParallaxHeroSection />} />
                <Route path="/paneofglass" element={<PaneOfGlass />} />
                {/* ###### ATHLETE ##################################################### */}
                <Route path="/athlete/profile" element={<AthleteProfile />} />
                <Route
                  path="/athlete/registrations"
                  element={<AthleteRegistrations />}
                />
                <Route path="/athlete/orders" element={<AthleteOrders />} />
                <Route
                  path="/athlete/dashboard"
                  element={<AthleteDashboard />}
                />
                {/* ###### EVENTS ##################################################### */}
                <Route
                  path="/events/:eventInstanceId"
                  element={<EventInstancePage />}
                />
                <Route
                  path="/events/:eventInstanceId/registration"
                  element={<EventRegistration />}
                />
                <Route
                  path="/events/:eventInstanceId/registration/:registrationId"
                  element={<ManageEventRegistration />}
                />
                <Route
                  path="/events/:eventInstanceId/registration/:registrationId/payment"
                  element={<EventRegistrationPayment />}
                />
                <Route
                  path="/events/:eventInstanceId/registration/:registrationId/payment-success"
                  element={<EventRegistrationPaymentSuccess />}
                />

                <Route
                  path="/leaderboard/:eventInstanceId"
                  element={<Leaderboard />}
                />

                {/* ###### REGISTRATIONS ##################################################### */}

                <Route
                  path="/registration/:registrationId"
                  element={<ManageEventRegistration />}
                />

                {/* ###### CHECKOUT ##################################################### */}
                <Route path="/checkout" element={<CheckoutPage />} />
                {/* ###### Organizer ######################################################## */}
                <Route
                  path="/organizer"
                  element={<Navigate to="/organizer/dashboard" />}
                />
                <Route
                  path="/organizer/dashboard"
                  element={<OrganizerDashboard />}
                />

                <Route
                  path="/organizer/organization"
                  element={<OrganizerOrganizationEditor />}
                />

                <Route
                  path="/organizer/events"
                  element={<OrganizerEventsList />}
                />
                <Route
                  path="/organizer/events/create"
                  element={<OrganizerEventCreate />}
                />
                <Route
                  path="/organizer/events/:eventId"
                  element={<OrganizerEventDetails />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId"
                  element={<OrganizerEventInstanceSetup />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/setup"
                  element={<OrganizerEventInstanceSetup />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/participants"
                  element={<OrganizerEventInstanceParticipants />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/workouts"
                  element={<OrganizerEventInstanceWorkouts />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/judging"
                  element={<OrganizerEventInstanceJudging />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/overview"
                  element={<OrganizerEventInstanceSetup />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/merchandise"
                  element={<OrganizerEventInstanceSetup />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/financials"
                  element={<OrganizerEventInstanceSetup />}
                />
                <Route
                  path="/organizer/events/:eventId/:eventInstanceId/leaderboard"
                  element={<OrganizerEventInstanceLeaderboard />}
                />

                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
              {/* </ArgonBox> */}
            </SnackbarProvider>
          </ThemeProvider>
        </ApolloProvider>
      </Suspense>
    </>
  );
}
