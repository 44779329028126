import { Card, Grid, Typography } from '@mui/material';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import React, { useEffect, useState } from 'react';

import { getShoppingCartItems } from 'services/shoppingCart';

import { useTranslation } from 'react-i18next';

import { ItemRow } from './ItemRow';
import { useLazyQuery, useQuery } from '@apollo/client';
import { REGISTRATION_GET_BY_ID } from 'GraphQL/Registrations/queries';
import { createNewOrder } from 'services/restApi';
import { ORGANIZATION_GET_BY_ID } from 'GraphQL/Organization/queries';
import Loader from 'Loader';

import { useSnackbar } from 'notistack';
import { deleteOrganizationFromShoppingCart } from 'services/shoppingCart';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function ItemsPerOrganization({
  organizationId,
  shoppingCart,
  onUpdate
}) {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [items, setItems] = useState(shoppingCart);
  const [total, setTotal] = useState(0);
  const [currency, setCurrency] = useState('EUR');
  const [includeShippingFee, setIncludeShippingFee] = useState(false);

  const [workInProgress, setWorkInProgress] = useState(false);

  const { data, loading, error } = useQuery(ORGANIZATION_GET_BY_ID, {
    variables: { id: organizationId },
    onCompleted: (result) => {
      console.log('Organization found:', result);
    },
    onError: (error) => {
      console.log('Error loading organization: ', error);
    }
  });

  const [getRegistrationData] = useLazyQuery(REGISTRATION_GET_BY_ID, {
    onCompleted: (result) => {
      console.log('Registration found:', result);
    },
    onError: (error) => {
      console.log('Error: ', error);
    }
  });

  //   console.log(
  //     'shoppingCart received on ItemsPerOrganization component:',
  //     shoppingCart
  //   );

  useEffect(() => {
    // console.log(
    //   'shoppingCart received on ItemsPerOrganization component:',
    //   shoppingCart
    // );
    if (items) {
      // calculate total
      for (const [key, value] of Object.entries(items)) {
        if (value.type === 'registration') {
          console.log('RegistrationId to checkout: ', value?.registrationId);

          getRegistrationData({
            variables: { id: value?.registrationId }
          }).then((result) => {
            console.log('Data from getRegistrationData', result);
            console.log('amountDue:', result?.data?.registration?.amountDue);
            console.log('systemFee:', result?.data?.registration?.systemFee);
            setTotal(
              (prev) =>
                prev +
                (result?.data?.registration?.amountDue || 0) +
                (result?.data?.registration?.systemFee || 0)
            );
          });
        }
      }
    }
  }, [shoppingCart, items]);

  const onItemDeleted = (item) => {
    console.log('Item to delete: ', item);
    setTotal(0);
    setItems(getShoppingCartItems());
  };

  const performCheckout = async (event) => {
    event.preventDefault();

    setWorkInProgress(true);

    const registrations = [];
    const merchandise = [];
    const tickets = [];

    for (const [key, value] of Object.entries(items)) {
      console.log('Processing item to create order:', value);
      if (value.type === 'registration') {
        registrations.push(value.registrationId);
      }
      if (value.type === 'merchandise') {
        merchandise.push(value.merchandiseId);
      }
      if (value.type === 'ticket') {
        tickets.push(value.ticketId);
      }
    }

    createNewOrder({
      organizationId,
      registrations,
      merchandise,
      tickets
    })
      .then((result) => {
        // now delete this order from cookie

        deleteOrganizationFromShoppingCart(organizationId);

        onUpdate && onUpdate();

        if (total == 0)
          Swal.fire({
            icon: 'success',
            title: 'Good job!',
            text: 'You have successfully registered for this event. You will be forwarded to your personal dashboard.'
          }).then(() => {
            navigate(
              // `/events/${getRegistrationData?.registration?.event}/registration/${getRegistrationData?.registration?._id}`
              `/athlete/dashboard`
            );
          });
        else if (result?.order?.checkoutSessionUrl)
          window.open(result?.order?.checkoutSessionUrl, '_blank');

        console.log('New order created:', result);
      })
      .catch((error) => {
        console.error('Error creating new order:', error);
        enqueueSnackbar('Error creating your order', { variant: 'error' });
      })
      .finally(() => {
        setWorkInProgress(false);
      });
  };

  const CreateItemTable = ({ itemsObj }) => {
    console.log('Shopping cart items to render: ', itemsObj);

    if (Object.keys(items).length === 0)
      return (
        <Typography variant="body2">
          &nbsp;&nbsp;No items in your shopping cart.
        </Typography>
      );

    return Object.keys(items).map((key) => {
      // for (const [key, value] of Object.entries(itemsObj)) {
      console.log('Key from object', key);
      console.log('Value from object', itemsObj[key]);
      if (itemsObj[key].type === 'registration') {
        return (
          <ItemRow
            key={organizationId + '-' + key}
            registrationId={itemsObj[key].registrationId}
            objectKey={key}
            onDeleted={onItemDeleted}
          />
        );
      }
      if (itemsObj[key].type === 'merchandise') {
        setIncludeShippingFee(true);
      }
    });
  };

  if (loading) return <Loader />;

  // console.log('Organization Data: ', data);

  if (data)
    return (
      <>
        <Card>
          <ArgonBox p={4} mb={4}>
            <Typography variant="h4">{data?.organization?.name}</Typography>
          </ArgonBox>
          <ArgonBox p={4} mb={4}>
            {/* <CreateShoppingCartTable /> */}

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                {/* <NoImagePlaceholder /> */}
                <ArgonBox pl={1}>
                  <Typography variant="body2" fontWeight={'bold'}>
                    Item
                  </Typography>
                </ArgonBox>
              </Grid>
              <Grid item xs={6} md={2}>
                <ArgonBox display="flex" justifyContent="end">
                  <Typography variant="body2" fontWeight={'bold'}>
                    Price
                  </Typography>
                </ArgonBox>
              </Grid>

              <Grid item xs={6} md={2}>
                <ArgonBox display="flex" justifyContent="end">
                  <Typography variant="body2" fontWeight={'bold'}>
                    Quantity
                  </Typography>
                </ArgonBox>
              </Grid>
              <Grid item xs={6} md={2}>
                <ArgonBox display="flex" justifyContent="end">
                  <Typography variant="body2" fontWeight={'bold'}>
                    Amount
                  </Typography>
                </ArgonBox>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ArgonBox style={{ borderBottom: '1px solid #e0e0e0' }} mb={2} />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CreateItemTable itemsObj={items} />
              </Grid>
              <Grid item xs={12}>
                <ArgonBox
                  style={{ borderBottom: '1px solid #e0e0e0' }}
                  mb={2}
                  mt={2}
                />
              </Grid>

              {includeShippingFee && (
                <>
                  <Grid item xs={7}>
                    <ArgonBox pl={1} mt={1}></ArgonBox>
                  </Grid>
                  <Grid item xs={3}>
                    <ArgonBox pl={1} mt={1}>
                      <Typography variant="body2" fontWeight={'regular'}>
                        ShippingFee
                      </Typography>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={2}>
                    <ArgonBox pl={1} mt={1} display="flex" justifyContent="end">
                      <Typography variant="body2" fontWeight={'regular'}>
                        XXX EUR
                      </Typography>
                    </ArgonBox>
                  </Grid>
                </>
              )}

              <Grid item xs={7}>
                <ArgonBox pl={1} mt={0}></ArgonBox>
              </Grid>
              <Grid item xs={3}>
                <ArgonBox pl={1} mt={0}>
                  <Typography variant="body2" fontWeight={'regular'}>
                    <b>Total</b> (incl. VAT&#178;)
                  </Typography>
                </ArgonBox>
              </Grid>
              <Grid item xs={2}>
                <ArgonBox pl={1} mt={0} display="flex" justifyContent="end">
                  <Typography variant="body2" fontWeight={'regular'}>
                    {Intl.NumberFormat(i18n.language, {
                      style: 'currency',
                      currency: currency || 'EUR'
                    }).format(total / 100)}
                  </Typography>
                </ArgonBox>
              </Grid>

              {/* <Grid item xs={7}>
                <ArgonBox pl={1} mt={0}></ArgonBox>
              </Grid>

              <Grid item xs={3}>
                <ArgonBox pl={1} mt={0}>
                  <Typography variant='body2' fontWeight={'regular'}>
                    Incl. VAT&#178;
                  </Typography>
                </ArgonBox>
              </Grid>

              <Grid item xs={2}>
                <ArgonBox pl={1} mt={0} display='flex' justifyContent='end'>
                  <Typography variant='body2' fontWeight={'regular'}>
         
                  </Typography>
                </ArgonBox>
              </Grid> */}

              <Grid item xs={12}>
                <ArgonBox pl={1} mt={4} display="flex" justifyContent="end">
                  <ArgonButton
                    id="checkout-button"
                    variant="gradient"
                    color="success"
                    onClick={performCheckout}
                    loading={workInProgress}
                    style={{ width: '200px' }}>
                    {total > 0 ? 'Proceed to payment' : 'Finish'}
                  </ArgonButton>
                </ArgonBox>
              </Grid>
            </Grid>
          </ArgonBox>
        </Card>
      </>
    );
}
