import React, { useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

import { EVENTINSTANCES_GET_BY_EVENTID } from 'GraphQL/EventInstances/queries';
import { useQuery } from '@apollo/client';

import ArgonTypography from 'components/ArgonTypography';
import { useArgonController } from 'context';
import { Card, Collapse, Stack } from '@mui/material';
import selectData from 'options/selectData';

import NoImagePlaceholder from 'assets/images/placeholder/no_image.png';

import SettingsIcon from '@mui/icons-material/Settings';
import TuneIcon from '@mui/icons-material/Tune';
import AddIcon from '@mui/icons-material/Add';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from 'Layout/DashboardLayout';
import { Link } from 'react-router-dom';
import { EVENT_GET_BY_ID } from 'GraphQL/Events/queries';
import { checkIfUserHasOrganizerAccess } from 'services/cognito/cognitoAccount';
import Loader from 'Loader';

function BaseLayout({ currentlySelectedEventInstanceId, eventId, children }) {
  // const navigate = useNavigate();

  const [hasAccess, setHasAccess] = useState('loading');
  const navigate = useNavigate();

  useEffect(() => {
    // check if user has required access (cognito groups), if not forward to login page
    checkIfUserHasOrganizerAccess().then((result) => {
      if (!result) {
        navigate('/login', { replace: true });
      }

      if (result) setHasAccess(true);
    });
  }, []);

  const eventData = useQuery(EVENT_GET_BY_ID, {
    variables: { eventId: eventId },
    onCompleted: async (data) => {
      console.log(
        'Event Baselayout: Event data received from Database: ',
        data
      );
    }
  });

  const { data, loading, error } = useQuery(EVENTINSTANCES_GET_BY_EVENTID, {
    variables: { eventId: eventId },
    onCompleted: async (data) => {
      console.log(
        'Event Instance Selection Wrapper: Event Instances Selection Wrapper received from Database: ',
        data
      );
    },
    onError: (error) => {
      console.error(
        'Event Instance Selection Wrapper Layout: Error getting event instances from Database: ',
        error
      );
    }
  });

  const handleEventInstanceSelected = (eventInstanceId) => {
    console.log('handleEventInstanceSelected', eventInstanceId);
    // navigate(`/organizer/events/${eventId}/${eventInstanceId}`);
  };

  // get event data from one of the eventInstances
  // const event = data?.eventInstances[0]?.event;
  const event = eventData?.data?.event;
  const eventLogo = event?.pic_logo_url || NoImagePlaceholder;

  if (hasAccess === 'loading') return <Loader />;

  if (hasAccess === true)
    return (
      <>
        <DashboardLayout>
          <Collapse in={data}>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12}>
                <ArgonBox mb={1}>
                  <Card
                    id="profile"
                    onClick={() => handleEventInstanceSelected(null)}>
                    <ArgonBox p={2}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <ArgonBox
                            height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center">
                            <ArgonBox
                              component="img"
                              src={eventLogo}
                              shadow="md"
                              borderRadius="md"
                              width="80px"
                              height="80px"
                              style={{
                                objectFit: 'cover'
                              }}
                            />
                          </ArgonBox>
                        </Grid>
                        <Grid item>
                          <ArgonBox height="100%" mt={0.5} lineHeight={1}>
                            <ArgonTypography variant="h5" fontWeight="medium">
                              {event?.name || 'New Event'}
                            </ArgonTypography>
                            <ArgonTypography
                              variant="button"
                              color="text"
                              fontWeight="medium">
                              {event?.descr_short}
                            </ArgonTypography>
                          </ArgonBox>
                        </Grid>
                        {/* <Grid item xs={12} md={6} lg={3} sx={{ ml: 'auto' }}> */}
                        {/* <ArgonBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <ArgonTypography variant="caption" fontWeight="regular">
                Switch to {visible ? "invisible" : "visible"}
              </ArgonTypography>
              <ArgonBox mx={1}>
                <Switch checked={visible} onChange={handleSetVisible} />
              </ArgonBox>
            </ArgonBox> */}
                        {/* </Grid> */}
                      </Grid>
                    </ArgonBox>
                  </Card>
                </ArgonBox>
              </Grid>
              {/* <Grid item xs={12}>
            {/* <ArgonBox mb={4}> */}
              {/* <Grid
                container
                // rowGap={0}
                // columnGap={0}
                rowSpacing={2}
                columnSpacing={2}
              > */}
              <Grid item xs={12} sm={6} md={3} xl={3}>
                <Link to={`/organizer/events/${eventId}`}>
                  <Card
                    sx={{
                      cursor: 'pointer',
                      boxShadow:
                        !currentlySelectedEventInstanceId &&
                        'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                      '&:hover': {
                        // opacity: 0.8,
                        transition: 'all 0.5s ease',
                        // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                        boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                        transform: 'translate(0px, -3px)'
                        // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 4px 4px 0px',
                        // filter: 'brightness(50%)',
                        // '*': 'inherit',
                      }
                    }}
                    onClick={() => handleEventInstanceSelected(null)}>
                    <ArgonBox p={1} pl={2} display="flex" alignItems="center">
                      <TuneIcon color="dark" />
                      &nbsp;
                      <ArgonTypography variant="button" noWrap>
                        {/* {event?.name || 'New Event'} */}
                        Event Setup
                      </ArgonTypography>
                    </ArgonBox>
                  </Card>
                </Link>
                {/* </ArgonBox> */}
              </Grid>

              {data?.eventInstances?.map((eventInstance) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    xl={3}
                    key={eventInstance?._id}>
                    {/* <ArgonBox style={{ minWidth: '250px' }}> */}
                    <Link
                      to={`/organizer/events/${eventId}/${eventInstance._id}`}>
                      <Card
                        sx={{
                          cursor: 'pointer',
                          boxShadow:
                            currentlySelectedEventInstanceId ===
                              eventInstance?._id &&
                            'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                          '&:hover': {
                            // opacity: 0.8,
                            transition: 'all 0.5s ease',
                            // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                            boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                            transform: 'translate(0px, -3px)'
                            // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 4px 4px 0px',
                            // filter: 'brightness(50%)',
                            // '*': 'inherit',
                          }
                        }}
                        onClick={() =>
                          handleEventInstanceSelected(eventInstance?._id)
                        }>
                        <ArgonBox
                          p={1}
                          pl={2}
                          display="flex"
                          alignItems="center">
                          <RocketLaunchIcon color="dark" />
                          &nbsp;
                          <ArgonTypography variant="button" noWrap>
                            {eventInstance?.name ||
                              selectData.eventInstanceTypes.find(
                                (type) => type.value === eventInstance?.type
                              )?.label}
                          </ArgonTypography>
                        </ArgonBox>
                      </Card>
                    </Link>
                    {/* </ArgonBox> */}
                  </Grid>
                );
              })}
              <Grid item xs={12} sm={6} md={3} xl={3}>
                {/* <ArgonBox mb={1} style={{ minWidth: '250px' }}> */}
                <Card
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: 'rgba(3, 102, 214, 0)',

                    boxShadow: 0,
                    //   selectedEventInstanceId === eventInstance?._id &&
                    //   'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                    '&:hover': {
                      // opacity: 0.8,

                      transition: 'all 0.5s ease',
                      // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                      boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                      transform: 'translate(0px, -3px)'
                      // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 4px 4px 0px',
                      // filter: 'brightness(50%)',
                      // '*': 'inherit',
                    }
                  }}
                  onClick={() => handleEventInstanceSelected()}>
                  <ArgonBox p={1} pl={2} display="flex" alignItems="center">
                    <AddIcon color="dark" />
                    &nbsp;
                    <ArgonTypography variant="button">
                      Add New Event Instance
                    </ArgonTypography>
                  </ArgonBox>
                </Card>
              </Grid>
            </Grid>
          </Collapse>
          {children}
        </DashboardLayout>
      </>
    );
}

export default BaseLayout;
