/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react';

// react-router-dom components
import { useLocation } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
  setLayout,
  setMiniSidenav,
  setOpenConfigurator
} from 'context';

// Images
import brand from 'assets/images/logos/logo_with_text_two_rows_white.png';
import brandDark from 'assets/images/logos/logo_with_text_two_rows_blue.png';

import Sidenav from 'Routes/Organizer/Dashboard/Sidenav';
import routes from 'routes';

function DashboardLayout({ bgColor, children, ...rest }) {
  const [controller, dispatch] = useArgonController();

  // setting Page Background Color
  document.body.style.backgroundColor = '#f8f9fa';

  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    darkSidenav,
    darkMode
  } = controller;

  // const { miniSidenav, darkMode } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
  }, [pathname]);

  const background = darkMode && !bgColor ? 'transparent' : bgColor;

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <ArgonBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        padding: 3,
        paddingTop: 2,

        [breakpoints.up('xl')]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard
          })
        }
      })}>
      <Sidenav
        color={sidenavColor}
        brand={darkSidenav || darkMode ? brand : brandDark}
        brandName="STRONG ONE"
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      {/* <ArgonBox
        bgColor={background || 'info'}
        height='122px'
        width='100vw'
        position='absolute'
        top={0}
        left={0}
        sx={
          darkMode && {
            bgColor: ({ palette: { background } }) => background.default,
          }
        }
        {...rest}
      /> */}
      {children}
    </ArgonBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default DashboardLayout;
