import { gql } from '@apollo/client';

export const EVENTINSTANCE_INSERT = gql`
  mutation InsertOneEventInstance($data: EventInstanceInsertInput!) {
    insertOneEventInstance(data: $data) {
      _id
      creationDate
      event {
        _id
        creationDate
        currency
        date_end
        date_start
        descr_long
        descr_short
        forIndividuals
        forTeams
        forUnderage
        hasOnlineQualifier
        name
        participantLimit
        participationFee
        pic_logo_url
        sports
        type
      }
      registrationDeadline
      startDate
      endDate
      description
      organizationId
      type
      isOpenRegistration
      name
      maxParticipants
      registrationFee
      isVirtualEvent
      divisions {
        _id
        name
        description
        maxParticipants
        forIndividuals
        forTeams
        forUnderage
        teamSize
        minAge
        maxAge
        registrationFee
        advancingAthletesNumber
        advancingAthletesPercentage
        assignedJudges
      }
      location {
        address1
        address2
        zip
        city
        country
        state
      }
    }
  }
`;

export const EVENTINSTANCE_UPDATE = gql`
  mutation UpdateOneEventInstance(
    $eventInstanceId: ObjectId!
    $data: EventInstanceUpdateInput!
  ) {
    updateOneEventInstance(query: { _id: $eventInstanceId }, set: $data) {
      _id
      creationDate
      event {
        _id
        creationDate
        currency
        date_end
        date_start
        descr_long
        descr_short
        forIndividuals
        forTeams
        forUnderage
        hasOnlineQualifier
        name
        participantLimit
        participationFee
        pic_logo_url
        sports
        type
        noAskForPlatformFee
      }
      registrationDeadline
      startDate
      endDate
      description
      organizationId
      type
      isOpenRegistration
      name
      maxParticipants
      registrationFee
      isVirtualEvent
      divisions {
        _id
        name
        description
        maxParticipants
        forIndividuals
        forTeams
        forUnderage
        teamSize
        minAge
        maxAge
        registrationFee
        advancingAthletesNumber
        advancingAthletesPercentage
        assignedJudges
      }
      location {
        address1
        address2
        zip
        city
        country
        state
      }
    }
  }
`;

export const EVENTINSTANCE_DELETE = gql`
  mutation DeleteEventInstance($eventInstanceId: ObjectId!) {
    deleteOneEventInstance(query: { _id: $eventInstanceId }) {
      _id
    }
  }
`;

export const EVENTINSTANCE_DIVISION_CREATE = gql`
  mutation eventInstanceDivisionCreate(
    $data: EventInstanceDivisionCreateInput
  ) {
    EventInstanceDivisionCreate(input: $data) {
      _id
      name
      description
      maxParticipants
      forIndividuals
      forTeams
      forUnderage
      teamSize
      minAge
      maxAge
      registrationFee
      advancingAthletesNumber
      advancingAthletesPercentage
      assignedJudges
    }
  }
`;

export const EVENTINSTANCE_DIVISION_UPDATE = gql`
  mutation updateInstanceEventDivision(
    $data: EventInstanceDivisionUpdateInput_!
  ) {
    EventInstanceDivisionUpdate(input: $data) {
      eventInstanceId
      division {
        name
        description
        maxParticipants
        forIndividuals
        forTeams
        forUnderage
        teamSize
        minAge
        maxAge
        registrationFee
        advancingAthletesNumber
        advancingAthletesPercentage
        assignedJudges
      }
    }
  }
`;

export const EVENTINSTANCE_DIVISION_DELETE = gql`
  mutation deleteInstanceDivision($data: EventInstanceDivisionDeleteInput!) {
    EventInstanceDivisionDelete(input: $data) {
      status
    }
  }
`;
