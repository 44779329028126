/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonAvatar from 'components/ArgonAvatar';

import NoImagePlaceholder from 'assets/images/placeholder/no_image.png';

// Images

import { Collapse } from '@mui/material';
import { checkIfUserHasOrganizerAccess } from 'services/cognito/cognitoAccount';
import { useNavigate } from 'react-router-dom';

function Header({ visible, title, subTitle, logoUrl }) {
  // const [visible, setVisible] = useState(true);
  // const handleSetVisible = () => setVisible(!visible);

  return (
    <Collapse in={visible}>
      <Card
        id="profile"
        sx={{
          cursor: 'pointer',
          '&:hover': {
            // opacity: 0.8,
            transition: 'all 0.5s ease',
            // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
            boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
            transform: 'translate(0px, -3px)'
            // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 4px 4px 0px',
            // filter: 'brightness(50%)',
            // '*': 'inherit',
          }
        }}>
        <ArgonBox p={2}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <ArgonBox
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center">
                <ArgonBox
                  component="img"
                  src={logoUrl || NoImagePlaceholder}
                  shadow="md"
                  borderRadius="md"
                  width="80px"
                  height="80px"
                  style={{
                    objectFit: 'cover'
                  }}
                />
              </ArgonBox>
              {/* <ArgonAvatar
                src={burceMars}
                alt="profile-image"
                variant="rounded"
                size="xl"
                shadow="sm"
              /> */}
            </Grid>
            <Grid item>
              <ArgonBox height="100%" mt={0.5} lineHeight={1}>
                <ArgonTypography variant="h5" fontWeight="medium">
                  {title}
                </ArgonTypography>
                <ArgonTypography
                  variant="button"
                  color="text"
                  fontWeight="medium">
                  {subTitle}
                </ArgonTypography>
              </ArgonBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3} sx={{ ml: 'auto' }}>
              {/* <ArgonBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <ArgonTypography variant="caption" fontWeight="regular">
                Switch to {visible ? "invisible" : "visible"}
              </ArgonTypography>
              <ArgonBox mx={1}>
                <Switch checked={visible} onChange={handleSetVisible} />
              </ArgonBox>
            </ArgonBox> */}
            </Grid>
          </Grid>
        </ArgonBox>
      </Card>
    </Collapse>
  );
}

export default Header;
