/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonSelect from 'components/ArgonSelect';
import ArgonTagInput from 'components/ArgonTagInput';
import { useArgonController } from 'context';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import selectData from 'layouts/pages/account/settings/components/BasicInfo/data/selectData';

import ArgonButton from 'components/ArgonButton';
import {
  Checkbox,
  Dialog,
  Divider,
  Switch,
  Table,
  TextField,
  Tooltip
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArgonTagInputSelectable from 'components/ArgonTagInputSelectable';

import ObjectID from 'bson-objectid';
import AutoCompleteTagsCreate from 'components/AutoCompleteTagsCreate.js';
import ArgonInput from 'components/ArgonInput';

import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { EVENTINSTANCE_DIVISION_CREATE } from 'GraphQL/EventInstances/mutations';
// import DivisionDetails from './DivisionDetails__';

import DivisionSummaryCard from './DivisionSummaryCard';
import CreateDivisionDialog from './CreateDivisionDialog';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function DivisionList({
  eventInstance,
  event,
  onAvailableAgeGroupsChanged
  // availableDivisions,
  // availableAgeGroups,
  // onAddDivision,
  // onRemoveDivision,
  // onAddAgeGroup,
  // onRemoveAgeGroup,
}) {
  // const [skills, setSkills] = useState(["react", "angular"]);

  const [controller] = useArgonController();
  const { darkMode } = controller;

  const [openCreateDivisionDialog, setOpenCreateDivisionDialog] =
    useState(false);

  const handleValuesChanged = (newValue) => {
    console.log(
      'HLC Divisions: handleFormikValuesChanged',

      newValue
    );
    onAvailableAgeGroupsChanged && onAvailableAgeGroupsChanged(newValue);
  };

  return (
    <>
      {/* <Card id="basic-info" sx={{ overflow: "visible" }}>
        <ArgonBox p={3}>
          <ArgonTypography variant="h5">Divisions & Age Groups</ArgonTypography>
        </ArgonBox>
        <ArgonBox component="form" pb={3} px={3}> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          {eventInstance?.divisions &&
            eventInstance?.divisions.map((division, index) => {
              return (
                <DivisionSummaryCard
                  key={division?._id}
                  // formik={formik}
                  eventId={eventInstance.eventId}
                  eventInstance={eventInstance}
                  division={division}
                  currency={event?.currency}
                  participationFee={eventInstance?.participationFee}
                  // availableAgeGroups={formik.values?.availableAgeGroups}
                  onFormikValuesChanged={handleValuesChanged}
                />
              );
            })}
          {/* <CreateDivision
                eventId={formik.values._id}
                formik={formik}
                currency={formik.values.currency}
                // onFormikValuesChanged={handleFormikValuesChanged}
                onAvailableAgeGroupsChanged={handleFormikValuesChanged}
              /> */}
        </Grid>
        <Grid item xs={12}>
          <ArgonBox
            mb={3}
            width="100%"
            display="flex"
            justifyContent="space-between">
            <ArgonBox mr={2}>
              <ArgonButton variant="outlined" color="secondary" size="small">
                Import divisions from another event instance
              </ArgonButton>
            </ArgonBox>
            <ArgonButton
              variant="gradient"
              color="success"
              size="small"
              onClick={() => setOpenCreateDivisionDialog(true)}>
              Add Division
            </ArgonButton>
          </ArgonBox>
        </Grid>
      </Grid>
      {/* </ArgonBox> */}
      {/* </Card> */}
      <Dialog
        open={openCreateDivisionDialog}
        onClose={() => setOpenCreateDivisionDialog(false)}>
        <CreateDivisionDialog
          eventInstance={eventInstance}
          currency={event?.currency}
          onClose={() => setOpenCreateDivisionDialog(false)}
        />
      </Dialog>
    </>
  );
}

export default DivisionList;
