/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

function DefaultNavbarDropdown({
  name,
  icon,
  children,
  collapseStatus,
  light,
  href,
  route,
  collapse,
  hideNameAndArrow,
  ...rest
}) {
  const linkComponent = {
    component: 'a',
    href,
    target: '_blank',
    rel: 'noreferrer'
  };

  const routeComponent = {
    component: Link,
    to: route
  };

  return (
    <>
      <ArgonBox
        {...rest}
        mx={0.5}
        p={1}
        display="flex"
        alignItems="baseline"
        // alignItems="center"
        color={light ? 'white' : 'dark'}
        sx={{ cursor: 'pointer', userSelect: 'none' }}
        {...(route && routeComponent)}
        {...(href && linkComponent)}>
        {icon && (
          <ArgonTypography
            variant="body2"
            lineHeight={1}
            color="inherit"
            sx={{ alignSelf: 'center', '& *': { verticalAlign: 'middle' } }}>
            {icon}
          </ArgonTypography>
        )}

        <ArgonTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? 'white' : 'dark'}
          sx={{ fontWeight: '100%', ml: 1, mr: 0.25 }}>
          {!hideNameAndArrow && name}
          {/* {name} */}
        </ArgonTypography>
        <ArgonTypography
          variant="body2"
          color={light ? 'white' : 'dark'}
          ml="auto">
          {!hideNameAndArrow && (
            <Icon
              sx={{
                fontWeight: 'normal',
                verticalAlign: 'middle'
              }}>
              {collapse && 'keyboard_arrow_down'}
            </Icon>
          )}
        </ArgonTypography>
      </ArgonBox>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of DefaultNavbarDropdown
DefaultNavbarDropdown.defaultProps = {
  icon: undefined,
  children: undefined,
  collapseStatus: false,
  light: false,
  href: '',
  route: ''
};

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired
};

export default DefaultNavbarDropdown;
