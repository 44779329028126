import React from 'react';
import PropTypes from 'prop-types';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

function PageHeadline(props) {
  const { headline } = props;
  return (
    <>
      <ArgonBox mb={4}>
        <ArgonTypography variant='h2' color='white' id='athlete-data'>
          {headline}
        </ArgonTypography>
      </ArgonBox>
    </>
  );
}

PageHeadline.propTypes = {
  headline: PropTypes.string.isRequired,
};

export default PageHeadline;
