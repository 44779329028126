/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for ArgonInput
import ArgonInputRoot from 'components/ArgonInput/ArgonInputRoot';

// Argon Dashboard 2 PRO MUI context
import { useArgonController } from 'context';
import ArgonTypography from 'components/ArgonTypography';

const ArgonInput = forwardRef(
  ({ size, error, success, helperText, disabled, ...rest }, ref) => {
    const [controller] = useArgonController();
    const { darkMode } = controller;

    return (
      <>
        <ArgonInputRoot
          {...rest}
          ref={ref}
          ownerState={{ size, error, success, disabled, darkMode }}
        />
        {helperText && (
          <ArgonTypography variant='caption' color='error'>
            {helperText}
          </ArgonTypography>
        )}
      </>
    );
  }
);

// Setting default values for the props of ArgonInput
ArgonInput.defaultProps = {
  size: 'medium',
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the ArgonInput
ArgonInput.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ArgonInput;
