import ArgonBox from 'components/ArgonBox';
import React from 'react';

export const shoppingCartRoutes = [
  {
    hideNameAndArrow: true,
    name: 'Shopping Cart', //loggedInUser.name,
    route: '/checkout',
    icon: (
      <ArgonBox
        component="i"
        color="dark"
        className="ni ni-bag-17"
        size="small"
      />
    ),
    collapse: [
      {
        name: 'Shopping Cart',
        route: '/checkout',
        icon: <ArgonBox component="i" color="info" className="ni ni-bag-17" />
      }
    ]
  }
];
