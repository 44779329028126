/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonSelect from 'components/ArgonSelect';
import ArgonTagInput from 'components/ArgonTagInput';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import selectData from 'options/selectData';
import ArgonButton from 'components/ArgonButton';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert, Icon, Switch, Table, TableBody, TableRow } from '@mui/material';

import TableCell from 'layouts/pages/account/settings/components/TableCell';

import ArgonDropzoneS3 from 'components/ArgonDropzoneS3';
import { Add as AddIcon } from '@mui/icons-material';
import { useArgonController } from 'context';

function BasicInfo({
  formik,
  onDeleteEvent,
  onSaveEvent,
  onUpdateEventLogoUrl,
  noGutter,
  onCreateNewEventInstance
}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  // const [skills, setSkills] = useState(["react", "angular"]);
  const [eventData, setEventData] = useState(formik?.values || {});

  const handleDeleteEventButton = () => {
    onDeleteEvent && onDeleteEvent();
  };

  const handleTextfieldChanged = (event) => {
    // event.preventDefault();
    // update eventData with event fired from textfields
    const update = { [event.target.name]: event.target.value };
    // console.log("update:", update);
    setEventData((prev) => ({ ...prev, ...update }));
  };

  const handleSwitchChanged = async (event) => {
    // event.preventDefault();
    // update eventData with event fired from textfields
    const update = { [event.target.name]: event.target.checked };
    // console.log("update:", update);
    setEventData((prev) => ({ ...prev, ...update }));
  };

  const handleSelectMenuChanged = (fieldName, option) => {
    const update = { [fieldName]: option };
    // console.log("update:", update);
    setEventData((prev) => ({ ...prev, ...update }));

    // update eventData with event fired from textfields
    // const update = { [event.target.name]: event.target.checked };
    // console.log("update:", update);
    // setEventData((prev) => ({ ...prev, ...update }));
  };

  const handleDatePickerChanged = (fieldName, newValue) => {
    if (newValue.length > 0) {
      const update = { [fieldName]: new Date(newValue[0]) };
      // console.log("update:", update);
      setEventData((prev) => ({ ...prev, ...update }));
    } else {
      console.error(
        'New picked date is an array with multiple date. Cannot update.'
      );
    }
  };

  const handleLogoUploaded = (url) => {
    console.log('Settiing event data with new picture url:', url);
    console.log('Event ID:', eventData._id);
    const tempEventData = { ...eventData, pic_logo_url: url };
    setEventData(tempEventData);

    // save event after logo has changed, but only if the event is already saved (has an id)
    // eventData._id && onSaveEvent && onSaveEvent(tempEventData);
    eventData._id && onUpdateEventLogoUrl && onUpdateEventLogoUrl(url);
  };

  const handleSaveEventButton = () => {
    console.log('Saving Event:', eventData);
    onSaveEvent && onSaveEvent(eventData);
  };

  // const validationSchema = Yup.object({
  //   name: Yup.string().required("Required"),
  //   // type: Yup.string().required("Required"),
  //   date: Yup.date().required("Required"),
  //   descr_short: Yup.string().required("Required"),
  //   descr_long: Yup.string().required("Required"),
  //   // forIndividuals: Yup.boolean().required("Required"),
  //   // forTeams: Yup.boolean().required("Required"),
  //   // forKids: Yup.boolean().required("Required"),
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     organizationId: "",
  //     name: "",
  //     type: "",
  //     date: "",
  //     descr_short: "",
  //     descr_long: "",
  //     hasOnlineQualifier: false,
  //     forIndividuals: false,
  //     forTeams: false,
  //     forKids: false,

  //     // pic_logo_url: String,
  //     // hasOnlineQualifier: Boolean,
  //     // participantLimit: Number,
  //     // participationFee: Number,
  //     // onlineQualifierEndDate: Date,
  //     // onlineQualifierStartDate: Date,
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: async (values) => {
  //     // alert(JSON.stringify(values, null, 2));
  //     handleCreateEvent(values)
  //       .then(() => {
  //         Swal.fire({
  //           title: "Good job!",
  //           text: "Your event has been created!",
  //           icon: "success",
  //         });
  //       })
  //       .catch((err) => {
  //         Swal.fire({
  //           title: "Whoops!",
  //           text: "Something went wrong. Please try again.",
  //           icon: "error",
  //         });
  //       });
  //   },
  // });

  // useEffect(() => {
  //   console.log("Formik values:", formik.values);
  // }, [formik]);

  // const handleCreateEvent = async () => {
  //   await createEvent(formik.values);
  // };

  if (!eventData) return <div>Loading...</div>;

  return (
    <>
      <Card id="basic-info" sx={{ overflow: 'visible' }}>
        <ArgonBox p={3}>
          <ArgonTypography variant="h5">Event Setup</ArgonTypography>
        </ArgonBox>
        <ArgonBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%">
                        <ArgonBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block">
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize">
                            sports
                          </ArgonTypography>
                        </ArgonBox>
                        <ArgonSelect
                          id="sports"
                          name="sports"
                          placeholder="Please choose"
                          options={selectData.sportsType}
                          //value={formik.values.type}
                          value={selectData.sportsType.filter(
                            (option) => option.value === eventData.sports
                          )}
                          onChange={(option) =>
                            handleSelectMenuChanged('sports', option.value)
                          }
                          error={
                            formik.touched.sports &&
                            Boolean(formik.errors.sports)
                          }
                          helperText={
                            formik.touched.sports && formik.errors.sports
                          }
                        />
                      </ArgonBox>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%">
                        <ArgonBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block">
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize">
                            event type
                          </ArgonTypography>
                        </ArgonBox>
                        <ArgonSelect
                          id="type"
                          name="type"
                          placeholder="Please choose"
                          options={selectData.eventType}
                          //value={formik.values.type}
                          value={selectData.eventType.filter(
                            (option) => option.value === eventData.type
                          )}
                          onChange={(option) =>
                            handleSelectMenuChanged('type', option.value)
                          }
                          error={
                            formik.touched.type && Boolean(formik.errors.type)
                          }
                          helperText={formik.touched.type && formik.errors.type}
                        />
                      </ArgonBox>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormField
                        id="name"
                        name="name"
                        label="Event Title"
                        placeholder="Your event name"
                        value={eventData?.name}
                        // onChange={formik.handleChange}
                        onChange={handleTextfieldChanged}
                        // onBlur={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    // backgroundColor: "grey",
                    paddingTop: '12px',
                    alignItems: 'flex-start'
                  }}>
                  <ArgonBox display="flex" flexDirection="column" height="100%">
                    <ArgonBox mb={0.5} mt={0} width="100%">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize">
                        Event Logo
                      </ArgonTypography>
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="regular"
                        // textTransform="capitalize"
                      >
                        &nbsp;(drop or click to change)
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox
                      mb={0}
                      mt={0}
                      height="100%"
                      // style={{ maxHeight: "150px" }}
                    >
                      <ArgonDropzoneS3
                        id="upload_logo_dropzone"
                        pathToPresignedUrl={
                          process.env.REACT_APP_S3_MEDIA_UPLOAD_PRESIGNED_URL
                        }
                        key={eventData?.pic_logo_url || 'emptyDropzone'}
                        onFileAdded={(url) => {
                          handleLogoUploaded(url);
                        }}
                        imagePurpose="eventLogo"
                        image={eventData?.pic_logo_url}
                        message=""
                        identifier={eventData._id}
                        options={{
                          // clickable: Boolean(eventData?._id),

                          paramName: 'file',

                          // dictDefaultMessage: "Put your custom message here",

                          method: 'PUT',
                          chunking: true,
                          chunkSize: 1000000,
                          parallelChunkUploads: true,
                          retryChunks: true,
                          retryChunksLimit: 3,
                          forceChunking: true,
                          // uploadMultiple: true,
                          parallelUploads: 3,

                          addRemoveLinks: true,
                          // maxFiles: 1,
                          acceptedFiles: 'image/*,.jpg,.jpeg,.png,.gif',
                          autoQueue: true,
                          autoProcessQueue: true
                        }}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs={12} sm={6}></Grid> */}
            {/* <Grid item xs={12} sm={12}>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  sports
                </ArgonTypography>
              </ArgonBox>
              <ArgonSelect
                id="sports"
                name="sports"
                placeholder="Please choose"
                options={selectData.sportsType}
                //value={formik.values.type}
                value={selectData.sportsType.filter(
                  (option) => option.value === eventData.sports
                )}
                onChange={(option) =>
                  handleSelectMenuChanged("sports", option.value)
                }
                error={formik.touched.sports && Boolean(formik.errors.sports)}
                helperText={formik.touched.sports && formik.errors.sports}
              />
            </ArgonBox>
          </Grid>

          <Grid item xs={12} sm={12}>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  event type
                </ArgonTypography>
              </ArgonBox>
              <ArgonSelect
                id="type"
                name="type"
                placeholder="Please choose"
                options={selectData.eventType}
                //value={formik.values.type}
                value={selectData.eventType.filter(
                  (option) => option.value === eventData.type
                )}
                onChange={(option) =>
                  handleSelectMenuChanged("type", option.value)
                }
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
              />
            </ArgonBox>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormField
              id="name"
              name="name"
              label="Event Title"
              placeholder="Your event name"
              value={eventData?.name}
              // onChange={formik.handleChange}
              onChange={handleTextfieldChanged}
              // onBlur={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid> */}
            <Grid item xs={12} sm={12}>
              <FormField
                id="descr_short"
                name="descr_short"
                label="Subtitle"
                placeholder="A short text to show as subtitle"
                value={eventData?.descr_short}
                onChange={handleTextfieldChanged}
                error={
                  formik.touched.descr_short &&
                  Boolean(formik.errors.descr_short)
                }
                helperText={
                  formik.touched.descr_short && formik.errors.descr_short
                }
              />
            </Grid>

            {/* Field currently not in use */}
            {/* <Grid item xs={12} sm={12}>
            <FormField
              id="descr_long"
              name="descr_long"
              label="long description"
              multiline
              rows={4}
              placeholder="A longer descriptive text"
              value={eventData?.descr_long}
              onChange={handleTextfieldChanged}
              error={
                formik.touched.descr_long && Boolean(formik.errors.descr_long)
              }
              helperText={formik.touched.descr_long && formik.errors.descr_long}
            />
          </Grid> */}

            <Grid item xs={12} sm={12}>
              <Table sx={{ minWidth: '36rem' }}>
                <ArgonBox component="thead">
                  <TableRow>
                    <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                      Search parameters (only used for finding events on the
                      website)
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      N/Y
                    </TableCell>
                  </TableRow>
                </ArgonBox>
                <TableBody>
                  <TableRow>
                    <TableCell padding={[1, 1, 1, 0.5]}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          variant="caption"
                          fontWeight="bold">
                          For individuals
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Competition offers separate categories for individuals
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      <Switch
                        id="forIndividuals"
                        name="forIndividuals"
                        value
                        onChange={handleSwitchChanged}
                        checked={eventData.forIndividuals}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding={[1, 1, 1, 0.5]}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          variant="caption"
                          fontWeight="bold">
                          For teams
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Competition offers separate categories for teams
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      <Switch
                        id="forTeams"
                        name="forTeams"
                        onChange={handleSwitchChanged}
                        checked={eventData.forTeams}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding={[1, 1, 1, 0.5]}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          // variant="button"
                          // fontWeight="regular"
                          variant="caption"
                          fontWeight="bold">
                          For kids
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Competition offers separate categories for kids
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      <Switch
                        id="forUnderage"
                        name="forUnderage"
                        onChange={handleSwitchChanged}
                        checked={eventData.forUnderage}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            {/* 
          <Grid item xs={12} sm={12}>
            <ArgonTypography
              display="block"
              variant="caption"
              fontWeight="bold"
              mb={1}
              ml={0.5}
            >
              Event Date
            </ArgonTypography>
            <ArgonDatePicker
              id="date"
              name="date"
              input={{
                placeholder: "Select a date",
                // value: moment(formik.values.date).format("LL"),
                // value: formik.values.date,
              }}
              // onChange={handleDatePickerChanged}
              // onChange={(date) => formik.setFieldValue("date", date)}
              onChange={(date) => handleDatePickerChanged("date", date)}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
              value={formik.values.date}
              dateFormat="d-M-yyyy"
            />
          

            <ArgonButton variant="text" color="info">
              <Icon>add</Icon>&nbsp;Add event date
            </ArgonButton>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Table sx={{ minWidth: "36rem" }}>
              <ArgonBox component="thead">
                <TableRow>
                  <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                    Search parameters (only used for finding events on the
                    website)
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    N/Y
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                    <ArgonBox lineHeight={1.4}>
                      <ArgonTypography
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Online qualifier
                      </ArgonTypography>
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                      >
                        Competition will have an online qualifier.
                      </ArgonTypography>
                    </ArgonBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Switch
                      id="hasOnlineQualifier"
                      name="hasOnlineQualifier"
                      onChange={(event) => handleSwitchChanged(event)}
                      checked={eventData?.hasOnlineQualifier}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding={[1, 1, 1, 0.5]}>
                    <ArgonBox lineHeight={1.4}>
                      <ArgonTypography
                        display="block"
                        // variant="button"
                        // fontWeight="regular"
                        variant="caption"
                        fontWeight="bold"
                      >
                        For kids
                      </ArgonTypography>
                      <ArgonTypography
                        variant="caption"
                        color="text"
                        fontWeight="regular"
                      >
                        Competition offers separate categories for kids
                      </ArgonTypography>
                    </ArgonBox>
                  </TableCell>
                  <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                    <Switch
                      id="forUnderage"
                      name="forUnderage"
                      onChange={handleSwitchChanged}
                      checked={eventData.forUnderage}
                    />
                  </TableCell>
                </TableRow>
              </ArgonBox>
            </Table>
          </Grid> */}

            <Grid item xs={12} sm={12}>
              <ArgonBox display="flex" justifyContent="space-between">
                <ArgonButton
                  id="btnSubmit"
                  variant="gradient"
                  color="info"
                  // onClick={formik.submitForm}
                  onClick={handleSaveEventButton}>
                  {eventData._id === undefined ||
                  eventData._id === null ||
                  eventData._id === ''
                    ? 'Next Step'
                    : 'Save Event'}
                </ArgonButton>
                <ArgonButton
                  id="btnDelete"
                  variant="text"
                  color="error"
                  onClick={handleDeleteEventButton}>
                  <Icon>delete</Icon>&nbsp;Delete
                </ArgonButton>
              </ArgonBox>
            </Grid>
          </Grid>
        </ArgonBox>
      </Card>

      <ArgonBox mt={3}>
        <Card>
          <ArgonBox p={3}>
            <ArgonTypography variant="h5">
              Create Event Instances
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                {/* <ArgonBox
              component="li"
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              borderRadius="lg"
              pl={3}
              pr={3}
              mb={noGutter ? 0 : 1}
              mt={2}
              sx={({ palette: { grey, background } }) => ({
                backgroundColor: darkMode ? background.default : grey[100],
              })}
            > */}

                {/* <Table sx={{ minWidth: '36rem' }}>
                  <ArgonBox component="thead">
                    <TableRow>
                      <TableCell
                        width="100%"
                        rowSpan={2}
                        padding={[1, 1, 1, 0.5]}>
                        Event Instances
                      </TableCell>
                    </TableRow>
                  </ArgonBox>
                  {/* <TableRow>
                <TableCell
                  padding={[1, 1, 1, 0.5]}
                  rowSpan={2}
                  style={{ border: '0' }}> */}

                {/* </TableCell>
              </TableRow> */}
                {/* </Table> */}
                <ArgonBox mt={2}>
                  <Alert severity="info">
                    All events need to have at least one event instance.
                  </Alert>
                </ArgonBox>

                <ArgonBox mt={2} />
                <ArgonButton
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={({ palette: { grey, background } }) => ({
                    backgroundColor: darkMode ? background.default : grey[100]
                  })}
                  onClick={() =>
                    onCreateNewEventInstance &&
                    onCreateNewEventInstance({ type: 'onlineQualifier' })
                  }>
                  <ArgonBox width="100%" display="flex" flexDirection="column">
                    <ArgonBox
                      display="flex"
                      justifyContent="left"
                      alignItems={{ xs: 'flex-start', sm: 'center' }}
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      mb={3}
                      mt={3}
                      // onClick={handleExpandClick}
                      // style={{ cursor: "pointer" }}
                    >
                      <ArgonBox
                        display="flex"
                        alignItems="center"
                        mt={{ xs: 2, sm: 0 }}
                        ml={{ xs: -1.5, sm: 0 }}>
                        <AddIcon fontSize="lg" color="info" />
                      </ArgonBox>
                      <ArgonTypography
                        variant="button"
                        fontWeight="medium"
                        // textTransform="capitalize"
                        ml={2}>
                        Add a virtual event (e.g. an online qualifier)
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonButton>
                {/* </ArgonBox> */}
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* <ArgonBox
              component="li"
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              borderRadius="lg"
              pl={3}
              pr={3}
              mb={noGutter ? 0 : 1}
              mt={2}
              sx={({ palette: { grey, background } }) => ({
                backgroundColor: darkMode ? background.default : grey[100],
              })}
            > */}
                <ArgonButton
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={({ palette: { grey, background } }) => ({
                    backgroundColor: darkMode ? background.default : grey[100]
                  })}
                  onClick={() =>
                    onCreateNewEventInstance && onCreateNewEventInstance({})
                  }>
                  <ArgonBox width="100%" display="flex" flexDirection="column">
                    <ArgonBox
                      display="flex"
                      justifyContent="left"
                      alignItems={{ xs: 'flex-start', sm: 'center' }}
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      mb={3}
                      mt={3}
                      // onClick={handleExpandClick}
                      // style={{ cursor: "pointer" }}
                    >
                      <ArgonBox
                        display="flex"
                        alignItems="center"
                        mt={{ xs: 2, sm: 0 }}
                        ml={{ xs: -1.5, sm: 0 }}>
                        <AddIcon fontSize="lg" color="info" />
                      </ArgonBox>
                      <ArgonTypography
                        variant="button"
                        fontWeight="medium"
                        // textTransform="capitalize"
                        ml={2}>
                        Add on-site event instance (e.g. a finale)
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonButton>
                {/* </ArgonBox> */}
              </Grid>
            </Grid>
          </ArgonBox>
        </Card>
      </ArgonBox>
    </>
  );
}

export default BasicInfo;
