/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for ArgonInput
import ArgonInputRoot from 'components/ArgonCurrencyInput/ArgonInputRoot';
import InputBase from '@mui/material/InputBase';

// Argon Dashboard 2 PRO MUI context
import { useArgonController } from 'context';
import { Input, TextField } from '@mui/material';

const ArgonCurrencyInput = forwardRef(
  ({ size, error, success, disabled, ...rest }, ref) => {
    const [controller] = useArgonController();
    const { darkMode } = controller;

    return (
      <ArgonInputRoot
        // customInput={TextField}
        customInput={InputBase}
        // size={size}
        // error={error}
        // success={success}
        {...rest}
        ref={ref}
        ownerState={{ size, error, success, disabled, darkMode }}
        // ownerState={{ disabled, darkMode }}
        // style={{
        //   padding: '0 12px',
        //   maxWidth: '100px',
        //   minHeight: '30px',
        //   borderRadius: '8px',
        //   border: '1px solid #ced4da',
        //   color: '#495057',
        // }}
      />
    );
  }
);

// Setting default values for the props of ArgonInput
ArgonCurrencyInput.defaultProps = {
  size: 'medium',
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the ArgonInput
ArgonCurrencyInput.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ArgonCurrencyInput;
