const { gql } = require('@apollo/client');

export const EVENTS_GET_ALL = gql`
  query Events($data: EventQueryInput) {
    events(query: $data) {
      _id
      organization {
        _id
        address1
        address2
        city
        country
        createdBy
        creationDate
        email
        logo_url
        name
        state
        stripeAccountId
        stripeChargesEnabled
        taxId
        website
        zip
      }
      creationDate
      currency
      date_start
      date_end
      descr_long
      descr_short
      forIndividuals
      forUnderage
      forTeams
      hasOnlineQualifier
      name
      sports
      participantLimit
      participationFee
      pic_logo_url
      type
      subDivisionOptions {
        _id
        name
        description
        minAge
        maxAge
      }
      divisions {
        _id
        name
        description
        maxParticipants
        forIndividuals
        forTeams
        forUnderage
        teamSize
        minAge
        maxAge
        registrationFee
      }
      noAskForPlatformFee
      eventIsVisible
    }
  }
`;

export const EVENT_GET_BY_ID = gql`
  query getEventById($eventId: ObjectId!) {
    event(query: { _id: $eventId }) {
      _id
      organization {
        _id
        address1
        address2
        city
        country
        createdBy
        creationDate
        email
        logo_url
        name
        state
        stripeAccountId
        stripeChargesEnabled
        taxId
        website
        zip
      }
      creationDate
      currency
      date_start
      date_end
      descr_long
      descr_short
      forIndividuals
      forUnderage
      forTeams
      hasOnlineQualifier
      name
      sports

      participantLimit
      participationFee
      pic_logo_url
      type
      subDivisionOptions {
        _id
        name
        description
        minAge
        maxAge
      }
      divisions {
        _id
        name
        description
        maxParticipants
        forIndividuals
        forTeams
        forUnderage
        teamSize
        minAge
        maxAge
        registrationFee
      }
      noAskForPlatformFee
      eventIsVisible
    }
  }
`;
