import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getShoppingCartItems = () => {
  try {
    return cookies.get('tduCart') || {};
  } catch (error) {
    console.log('Error getting shopping cart items: ', error);
    return {};
  }
};

export const deleteShoppingCart = () => {
  try {
    return cookies.remove('tduCart');
  } catch (error) {
    console.log('Error removing shopping cart from cookies: ', error);
    return {};
  }
};

export const deleteItemFromShoppingCart = (organizationId, key) => {
  console.log('Key to delete: ', key);
  const existingItems = cookies.get('tduCart');

  if (!existingItems) return null;

  delete existingItems[organizationId][key];

  if (Object.keys(existingItems[organizationId]).length === 0)
    delete existingItems[organizationId];

  console.log('Updated shopping cart: ', existingItems);

  cookies.set('tduCart', existingItems, {
    path: '/',
    maxAge: 604800
  });
};

export const deleteOrganizationFromShoppingCart = (organizationId) => {
  console.log('Organization to delete: ', organizationId);
  const existingItems = cookies.get('tduCart');

  if (!existingItems) return null;

  delete existingItems[organizationId];
  console.log('Updated shopping cart: ', existingItems);

  cookies.set('tduCart', existingItems, {
    path: '/',
    maxAge: 604800
  });
};

export const addRegistrationToShoppingCart = ({
  organizationId,
  registrationId
}) => {
  console.log('Adding new registration to shopping cart: ', registrationId);

  // example structure of shopping cart:
  // const shoppingCart = {
  //     organizationId: {
  //         cartItemId: {
  //             type: 'registration', // or 'merchandise' or "ticket",
  //             registration: registrationId,
  //             // merchandise: merchandiseItemId,
  //             // ticket: ticketId,
  //         }
  //     }
  // }

  // items are grouped by organizations for billing / payment purposes (payment oper organization)

  //
  // if (!organizationId in existingItems) {

  // }

  try {
    const newItem = { type: 'registration', registrationId };

    const existingItems = cookies.get('tduCart');
    // console.log('unparsed: ', unparsed, typeof unparsed);

    if (!existingItems) {
      // if no items in shopping cart, add new item for new organization
      console.log('No shopping cart items found. Adding new item...');

      // check if organizationId is already in cart

      const item = { [organizationId]: { 0: newItem } };
      console.log('Item to add: ', item);

      cookies.set('tduCart', item, {
        path: '/',
        maxAge: 604800
      });
    } else {
      // if items in shopping cart, add new item to existing organizationId

      // 1) check if organizationId is already in cart, if yes, add new item to existing organizationId
      if (organizationId in existingItems) {
        console.log('OrganizationId already in cart');

        const maxKey = Math.max(...Object.keys(existingItems[organizationId]));

        console.log('maxKey: ', maxKey);

        // add new item to shopping cart object
        existingItems[organizationId][maxKey + 1] = newItem;

        // store cookie from updated object
        cookies.set('tduCart', existingItems, {
          path: '/',
          maxAge: 604800
        });
      } else {
        // 3) if no, add new organizationId to cart
        const updatedItems = {
          ...existingItems,
          [organizationId]: { 0: newItem }
        };
        cookies.set('tduCart', updatedItems, {
          path: '/',
          maxAge: 604800
        });
      }
    }

    console.log('Updated cookie: ', cookies.get('tduCart'));
    return true;
  } catch (error) {
    console.log('Error adding shopping cart item: ', error);
    return null;
  }
};

export const addMerchandiseToShoppingCart = ({
  merchandiseItemId,
  merchandiseItemQuantity
}) => {
  console.log('Adding new item to shopping cart: ', merchandiseItemId);

  try {
    const existingItems = cookies.get('tduCart');
    // console.log('unparsed: ', unparsed, typeof unparsed);

    if (!existingItems) {
      console.log('No shopping cart items found. Adding new item...');

      const item = { 0: { merchandiseItemId, merchandiseItemQuantity } };

      cookies.set('tduCart', item, {
        path: '/',
        maxAge: 604800
      });
    } else {
      // get max key from existing items
      const maxKey = Math.max(...Object.keys(existingItems));
      console.log('maxKey: ', maxKey);

      // add new item to shopping cart object
      existingItems[maxKey + 1] = {
        type: 'merchandise',
        merchandiseItemId,
        merchandiseItemQuantity
      };

      // store cookie from updated object
      cookies.set('tduCart', existingItems, {
        path: '/',
        maxAge: 604800
      });
    }
    console.log('Updated cookie: ', cookies.get('tduCart'));
    return true;
  } catch (error) {
    console.log('Error adding shopping cart item: ', error);
    return null;
  }
};
