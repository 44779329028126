/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from '../../../Layout/DashboardLayout';
import DashboardNavbar from './Navbar';
import Footer from 'examples/Footer';
import DetailedStatisticsCard from 'examples/Cards/StatisticsCards/DetailedStatisticsCard';
import SalesTable from 'examples/Tables/SalesTable';
import Table from 'examples/Tables/Table';
import CategoriesList from 'examples/Lists/CategoriesList';
import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart';

// Argon Dashboard 2 PRO MUI base styles
import typography from 'assets/theme/base/typography';

// Dashboard layout components
import Slider from 'layouts/dashboards/default/components/Slider';
import TeamMembers from 'layouts/dashboards/default/components/TeamMembers';
import TodoList from 'layouts/dashboards/default/components/TodoList';
import ProgressTrack from 'layouts/dashboards/default/components/ProgressTrack';
import BalanceCard from 'layouts/dashboards/default/components/BalanceCard';
import CryptoCard from 'layouts/dashboards/default/components/CryptoCard';

// Pages layout components
import Post from 'layouts/pages/profile/teams/components/Post';

// Data
import reportsBarChartData from 'layouts/dashboards/default/data/reportsBarChartData';
import gradientLineChartData from 'layouts/dashboards/default/data/gradientLineChartData';
import projectsTableData from 'layouts/dashboards/default/data/projectsTableData';
import salesTableData from 'layouts/dashboards/default/data/salesTableData';
import authorsTableData from 'layouts/dashboards/default/data/authorsTableData';
import categoriesListData from 'layouts/dashboards/default/data/categoriesListData';

function Default() {
  const { size } = typography;
  return (
    <DashboardLayout bgColor='#466B72'>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="today's money"
              count='$53,000'
              icon={{
                color: 'info',
                component: <i className='ni ni-money-coins' />,
              }}
              percentage={{
                color: 'success',
                count: '+55%',
                text: 'since yesterday',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="today's users"
              count='2,300'
              icon={{
                color: 'error',
                component: <i className='ni ni-world' />,
              }}
              percentage={{
                color: 'success',
                count: '+3%',
                text: 'since last week',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title='new clients'
              count='+3,462'
              icon={{
                color: 'success',
                component: <i className='ni ni-paper-diploma' />,
              }}
              percentage={{
                color: 'error',
                count: '-2%',
                text: 'since last quarter',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title='sales'
              count='$103,430'
              icon={{
                color: 'warning',
                component: <i className='ni ni-cart' />,
              }}
              percentage={{
                color: 'success',
                count: '+5%',
                text: 'than last month',
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} lg={7}>
            <GradientLineChart
              title='Sales Overview'
              description={
                <ArgonBox display='flex' alignItems='center'>
                  <ArgonBox
                    fontSize={size.lg}
                    color='success'
                    mb={0.3}
                    mr={0.5}
                    lineHeight={0}
                  >
                    <Icon sx={{ fontWeight: 'bold' }}>arrow_upward</Icon>
                  </ArgonBox>
                  <ArgonTypography
                    variant='button'
                    color='text'
                    fontWeight='medium'
                  >
                    4% more{' '}
                    <ArgonTypography
                      variant='button'
                      color='text'
                      fontWeight='regular'
                    >
                      in 2022
                    </ArgonTypography>
                  </ArgonTypography>
                </ArgonBox>
              }
              chart={gradientLineChartData}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Slider />
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={6} lg={4}>
            <TeamMembers />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TodoList />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ProgressTrack />
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} lg={5}>
            <Post />
          </Grid>
          <Grid container item xs={12} lg={7} spacing={3}>
            <Grid item xs={12} height='max-content'>
              <ArgonBox
                sx={{
                  '& .MuiTableContainer-root': {
                    p: 3,
                  },
                  '& .MuiTableRow-root:not(:last-child)': {
                    '& td': {
                      borderBottom: ({
                        borders: { borderWidth, borderColor },
                      }) => `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table
                  columns={projectsTableData.columns}
                  rows={projectsTableData.rows}
                />
              </ArgonBox>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} md={6}>
                <BalanceCard />
              </Grid>
              <Grid item xs={12} md={6}>
                <CryptoCard />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SalesTable title='Sales by Country' rows={salesTableData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <ArgonBox
              sx={{
                '& .MuiTableRow-root:not(:last-child)': {
                  '& td': {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table
                columns={authorsTableData.columns}
                rows={authorsTableData.rows}
              />
            </ArgonBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <CategoriesList
              title='categories'
              categories={categoriesListData}
            />
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
