import React from 'react';
import {
  Card,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import { useArgonController } from 'context';
import { Link, useNavigate } from 'react-router-dom';
import HorizontalBarChart from 'examples/Charts/BarCharts/HorizontalBarChart';
import ArgonProgress from 'components/ArgonProgress';
import NoImagePlaceholder from 'assets/images/placeholder/no_image.png';

const EventCard = ({ event, noGutter }) => {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const navigate = useNavigate();

  const [registrations, setRegistrations] = React.useState(
    Math.floor(Math.random() * 100)
  );

  return (
    <Link to={`${event?._id}`}>
      <ArgonBox
        component="li"
        display="flex"
        justifyContent="space-between"
        // alignItems="flex-start"
        alignItems="center"
        borderRadius="lg"
        pl={3}
        pr={3}
        py={2}
        mb={noGutter ? 0 : 1}
        mt={2}
        //   sx={({ palette: { grey, background } }) => ({
        //     backgroundColor: darkMode ? background.default : grey[200],
        //   })}
        sx={({ palette: { white, background } }) => ({
          backgroundColor: darkMode ? background.default : white.main
        })}
        shadow="xs"
        //   onClick={() => navigate(event?._id)}
        //   style={{ cursor: "pointer" }}
      >
        <ArgonBox
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mr={3}>
          <ArgonBox
            component="img"
            src={event?.pic_logo_url || NoImagePlaceholder}
            shadow="md"
            borderRadius="md"
            width="80px"
            height="80px"
            style={{
              objectFit: 'cover'
            }}
          />
        </ArgonBox>

        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={5}>
            <ArgonBox width="100%" mb={0}>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                textTransform="capitalize">
                {event?.name}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox width="100%" mb={0}>
              <ArgonTypography variant="caption" textTransform="capitalize">
                {/* {Intl.DateTimeFormat("de-DE", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(new Date(event?.date))} */}
              </ArgonTypography>
            </ArgonBox>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      width: '100px',
                      border: 0,
                      paddingBottom: 0,
                      paddingTop: 0,
                      marginBottom: 0
                    }}>
                    <ArgonTypography
                      //   style={{ padding: 0, marginBottom: 0 }}
                      variant="caption"
                      // fontWeight="medium"
                      textTransform="capitalize"
                      mb={0}>
                      Sports:
                    </ArgonTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      border: 0,
                      paddingBottom: 0,
                      paddingTop: 0,
                      marginBottom: 0
                    }}>
                    <ArgonTypography
                      //   style={{ padding: 0, marginBottom: 0 }}
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                      mb={0}>
                      {event?.sports}
                    </ArgonTypography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      width: '100px',
                      border: 0,
                      paddingBottom: 0,
                      paddingTop: 0,
                      marginBottom: 0
                    }}>
                    <ArgonTypography
                      variant="caption"
                      // fontWeight="medium"
                      textTransform="capitalize">
                      Event Type:
                    </ArgonTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      border: 0,
                      paddingBottom: 0,
                      paddingTop: 0,
                      marginBottom: 0
                    }}>
                    <ArgonTypography
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize">
                      {event?.type}
                    </ArgonTypography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={3}>


            <HorizontalBarChart
              title="Registrations"
              chart={{
                labels: ["0-100%"],
                datasets: [
                  {
                    label: "No of registered slots",
                    color: "dark",
                    data: [15],
                  },
                ],
              }}
            />
          </Grid> */}
          <Grid item xs={12} sm={12} md={12}>
            <ArgonTypography
              variant="caption"
              // fontWeight="medium"
              textTransform="capitalize">
              Registrations:&nbsp;{registrations}%
            </ArgonTypography>
            <ArgonProgress
              value={registrations}
              variant="gradient"
              //   label
            />
          </Grid>
        </Grid>
      </ArgonBox>
    </Link>
  );
  {
    /* <Card id="basic-info" sx={{ overflow: "visible" }}>
      Event
    </Card> */
  }
};

export default EventCard;
