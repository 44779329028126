import React, { useEffect, useState } from 'react';

import { PuffLoader } from 'react-spinners';
import ArgonBox from 'components/ArgonBox';
import { Grid, IconButton, Typography } from '@mui/material';

import { useQuery } from '@apollo/client';
import { REGISTRATION_GET_BY_ID } from 'GraphQL/Registrations/queries';
import selectData from 'options/selectData';

import ClearIcon from '@mui/icons-material/Clear';

import NoImagePlaceholder from 'assets/images/placeholder/no_image.png';
import { deleteItemFromShoppingCart } from 'services/shoppingCart';
import Swal from 'sweetalert2';

import { useTranslation } from 'react-i18next';
import ArgonTypography from 'components/ArgonTypography';

export const ItemRow = (props) => {
  const { t, i18n } = useTranslation();
  const { objectKey, registrationId, onDeleted, onItemTotalTopUp } = props;

  console.log('objectKey', objectKey);

  const { data, loading, error } = useQuery(REGISTRATION_GET_BY_ID, {
    variables: { id: registrationId },
    onCompleted: (result) => {
      console.log('Registration found:', result);

      //   onItemTotalTopUp &&
      //     onItemTotalTopUp(
      //       (result.registration?.amountDue || 0) +
      //         (result.registration?.systemFee || 2)
      //     );
    },
    onError: (error) => {
      console.log('Error: ', error);
    }
  });

  const handleRemoveRegistrationFromCart = (event) => {
    event.preventDefault();

    Swal.fire({
      title: 'Cancel registration for event',
      text: 'Are you sure you want to cancel your registration for this event?',
      icon: 'warning',
      confirmButtonText: 'Yes, I want to cancel',
      cancelButtonText: 'No, I want to remain registered',
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(
          'Cancel registration for event',
          data?.registration?.event.organizationId,
          objectKey
        );
        // remove registration from database
        deleteItemFromShoppingCart(
          data?.registration?.event.organizationId,
          objectKey
        );
        onDeleted && onDeleted(objectKey);
      }
    });
  };

  if (loading) return <PuffLoader />;
  if (error) return <p>Error: {error.message}</p>;
  if (data) {
    const division = data?.registration?.eventInstance?.divisions.find(
      (div) => div._id === data?.registration?.divisionId
    );
    const eventName = `${data?.registration?.event?.name} | ${
      data?.registration?.eventInstance?.name ||
      selectData.eventInstanceTypes.find(
        (st) => st.value === data?.registration?.eventInstance?.type
      )?.label
    } | ${
      data?.registration?.eventInstance?.divisions.find(
        (div) => div._id === data?.registration?.divisionId
      )?.name
    }
  `;

    return (
      <Grid container spacing={2}>
        {objectKey != 0 && (
          <Grid item xs={12}>
            <ArgonBox
              style={{ borderBottom: '1px solid #e0e0e0' }}
              //   mb={2}
              mt={1}
            />
          </Grid>
        )}
        <Grid item xs={6} md={1}>
          <ArgonBox display="flex" justifyContent="center" alignItems="center">
            <ArgonBox
              component="img"
              src={
                data?.registration?.event?.pic_logo_url || NoImagePlaceholder
              }
              shadow="md"
              borderRadius="md"
              width="100%"
              height="100%"
              maxWidth="100px"
              maxHeight="100px"
              style={{
                objectFit: 'cover'
              }}
            />
          </ArgonBox>
        </Grid>
        <Grid item xs={6} md={5}>
          <ArgonBox height="100%">
            <ArgonTypography variant="body2" fontWeight="bold">
              {data?.registration?.event?.name}
            </ArgonTypography>
            <ArgonTypography variant="body2">
              {`${
                data?.registration?.eventInstance?.name ||
                selectData.eventInstanceTypes.find(
                  (st) => st.value === data?.registration?.eventInstance?.type
                )?.label
              } | ${
                data?.registration?.eventInstance?.divisions.find(
                  (div) => div._id === data?.registration?.divisionId
                )?.name
              }
              `}
            </ArgonTypography>
          </ArgonBox>
        </Grid>
        <Grid item xs={6} md={2}>
          <ArgonBox
            display="flex"
            justifyContent="end"
            // alignItems="center"
            // mt={2}
          >
            <Typography variant="body2">
              {/* // TODO: add currency conversion if needed */}

              {Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: data.registration?.event?.currency || 'EUR'
              }).format((data.registration?.amountDue || 0) / 100)}
            </Typography>
          </ArgonBox>
        </Grid>

        <Grid item xs={6} md={2}>
          <ArgonBox
            display="flex"
            justifyContent="end"
            // alignItems="center"
            // mt={2}
          >
            <IconButton
              size="small"
              onClick={handleRemoveRegistrationFromCart}
              color="error">
              <ClearIcon />
            </IconButton>
            <Typography variant="body2">1</Typography>
          </ArgonBox>
        </Grid>
        <Grid item xs={6} md={2}>
          <ArgonBox
            display="flex"
            justifyContent="end"
            // alignItems="center"
            // mt={2}
          >
            <Typography variant="body2">
              {Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: data.registration?.event?.currency || 'EUR'
              }).format((data.registration?.amountDue || 0) / 100)}
            </Typography>
          </ArgonBox>
        </Grid>

        <Grid item xs={6} md={1}>
          <ArgonBox
            display="flex"
            justifyContent="center"
            alignItems="center"></ArgonBox>
        </Grid>
        <Grid item xs={6} md={5}>
          <ArgonBox
            display="flex"
            // justifyContent='center'
            // alignItems="center"
            height="100%"
            // style={{ backgroundColor: 'blue' }}
          >
            <Typography variant="body2">Platform Fee&#185;</Typography>
          </ArgonBox>
        </Grid>
        <Grid item xs={6} md={2}>
          <ArgonBox
            display="flex"
            justifyContent="end"
            alignItems="center"
            // mt={2}
          >
            <Typography variant="body2">
              {Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: data.registration?.event?.currency || 'EUR'
              }).format((data.registration?.systemFee || 0) / 100)}
            </Typography>
          </ArgonBox>
        </Grid>

        <Grid item xs={6} md={2}>
          <ArgonBox
            display="flex"
            justifyContent="end"
            alignItems="center"
            // mt={2}
          >
            <Typography variant="body2">1</Typography>
          </ArgonBox>
        </Grid>
        <Grid item xs={6} md={2}>
          <ArgonBox
            display="flex"
            justifyContent="end"
            alignItems="center"
            // mt={2}
          >
            <Typography variant="body2">
              {Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: data.registration?.event?.currency || 'EUR'
              }).format((data.registration?.systemFee || 0) / 100)}
            </Typography>
          </ArgonBox>
        </Grid>
      </Grid>
    );
  }
};
