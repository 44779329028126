/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonSelect from 'components/ArgonSelect';
import ArgonTagInput from 'components/ArgonTagInput';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import selectData from 'options/selectData';
import ArgonButton from 'components/ArgonButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ArgonDatePicker from 'components/ArgonDatePicker';
// import ObjectID from 'bson-objectid';
import {
  Collapse,
  Icon,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableHead,
  TableRow
} from '@mui/material';

import TableCell from 'layouts/pages/account/settings/components/TableCell';
import ArgonInput from 'components/ArgonInput';
import DivisionList from '../Divisions/DivisionList';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

import { EVENTINSTANCE_UPDATE } from 'GraphQL/EventInstances/mutations';
import { EVENTINSTANCE_DELETE } from 'GraphQL/EventInstances/mutations';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FormatMoney } from 'format-money-js';
import { useSnackbar } from 'notistack';
import { EVENTINSTANCE_GET_BY_ID } from 'GraphQL/EventInstances/queries';
import Loader from 'Loader';
import { EVENT_GET_BY_ID } from 'GraphQL/Events/queries';
import ArgonCurrencyInput from 'components/ArgonCurrencyInput';

function EventInstanceDetails({
  eventInstanceId,
  // eventInstance,
  // event,
  open,
  addNew,
  addNewEventType,
  onEventInstanceChanged
}) {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  moment.locale(i18n.language);

  const [workInProgress, setWorkInProgress] = useState(false);

  const [expanded, setExpanded] = useState(open || true);

  const [event, setEvent] = useState({});

  const [gqlUpdateEventInstance] = useMutation(EVENTINSTANCE_UPDATE);
  const [gqlDeleteEventInstance] = useMutation(EVENTINSTANCE_DELETE);

  const { data, loading } = useQuery(EVENTINSTANCE_GET_BY_ID, {
    variables: { eventInstanceId: eventInstanceId },
    onCompleted: async (data) => {
      console.log('onCompleted', data);

      await getEvent({ variables: { eventId: data.eventInstance?.eventId } });

      // provide formik with values from database

      formik.setFieldValue('_id', data.eventInstance?._id);
      formik.setFieldValue('eventId', data.eventInstance?.eventId);
      formik.setFieldValue('creationDate', data.eventInstance?.creationDate);

      // creationDate: data.eventInstance?.creationDate ||
      //   new Date().toDateString(),

      formik.setFieldValue(
        'type',
        data.eventInstance?.type || addNewEventType || ''
      );

      formik.setFieldValue('startDate', data.eventInstance?.startDate || '');
      formik.setFieldValue('endDate', data.eventInstance?.endDate || '');
      formik.setFieldValue(
        'isVirtualEvent',
        data.eventInstance?.isVirtualEvent || false
      );
      formik.setFieldValue(
        'isOpenRegistration',
        data.eventInstance?.isOpenRegistration || true
      );
      formik.setFieldValue('name', data.eventInstance?.name || '');
      formik.setFieldValue(
        'description',
        data.eventInstance?.description || ''
      );
      formik.setFieldValue(
        'registrationDeadline',
        data.eventInstance?.registrationDeadline || ''
      );
      formik.setFieldValue(
        'organizationId',
        data.eventInstance?.organizationId || ''
      );
      formik.setFieldValue(
        'maxParticipants',
        data.eventInstance?.maxParticipants || 0
      );
      formik.setFieldValue(
        'registrationFee',
        Number(data.eventInstance?.registrationFee) / 100 || 0
      );
      formik.setFieldValue(
        'address1',
        data?.eventInstance?.location?.address1 || ''
      );
      formik.setFieldValue(
        'address2',
        data?.eventInstance?.location?.address2 || ''
      );
      formik.setFieldValue('zip', data?.eventInstance?.location?.zip || '');
      formik.setFieldValue('city', data?.eventInstance?.location?.city || '');
      formik.setFieldValue(
        'country',
        data?.eventInstance?.location?.country || ''
      );
    },
    onError: (error) => {
      console.error('onError', error);
    }
  });

  const [getEvent] = useLazyQuery(EVENT_GET_BY_ID, {
    onCompleted: (data) => {
      console.log('onCompleted: event data fetched:', data);
      setEvent(data.event);
    }
  });

  const eventInstance = data?.eventInstance;

  const getDecimalSeparator = (locale) => {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale || i18n.language)
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === 'decimal').value;
  };

  const fm = new FormatMoney({
    decimals: 2,
    locale: i18n.language,
    decimalPoint: getDecimalSeparator(i18n.language)
  });

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const validationSchema = Yup.object({
    type: Yup.string()
      .oneOf(selectData.eventInstanceTypes.map((item) => item.value))
      .required('Required'),

    registrationDeadline: Yup.string().required('Required'),
    // registrationFee: Yup.number('Must be a valid number'),
    startDate: Yup.date('Must be a valid date').required('Required'),
    endDate: Yup.date('Must be a valid date').required('Required')
  });

  const formik = useFormik({
    initialValues: {
      _id: eventInstance?._id, //|| ObjectID.toHexString(),
      eventId: eventInstance?.eventId || '',
      creationDate: eventInstance?.creationDate || new Date().toDateString(),

      type: eventInstance?.type || addNewEventType || '',
      startDate: eventInstance?.startDate || '',
      endDate: eventInstance?.endDate || '',

      isVirtualEvent:
        eventInstance?.isVirtualEvent ||
        // Boolean(addNewEventType === "onlineQualifier") ||
        false,

      isOpenRegistration:
        eventInstance?.isOpenRegistration ||
        // Boolean(addNewEventType === "onlineQualifier") ||
        true,
      name: eventInstance?.name || '',
      description: eventInstance?.description || '',
      registrationDeadline: eventInstance?.registrationDeadline || '',
      organizationId: eventInstance?.organizationId || '',
      maxParticipants: eventInstance?.maxParticipants || 0,
      registrationFee: Number(eventInstance?.registrationFee) / 100 || 0,
      address1: eventInstance?.location?.address1 || '',
      address2: eventInstance?.location?.address2 || '',
      city: eventInstance?.location?.city || '',
      zip: eventInstance?.location?.zip || '',
      country: eventInstance?.location?.country || ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setWorkInProgress(true);

      // alert(JSON.stringify(values, null, 2));

      if (!addNew) {
        gqlUpdateEventInstance({
          variables: {
            eventInstanceId: values._id,
            data: {
              type: values.type,
              startDate: values.startDate,
              endDate: values.endDate,
              isVirtualEvent: values.isVirtualEvent,
              isOpenRegistration: values.isOpenRegistration,
              name: values.name,
              description: values.description,
              registrationDeadline: values.registrationDeadline,
              organizationId: values.organizationId,
              maxParticipants: values.maxParticipants,
              registrationFee: fm.un(values.registrationFee) * 100,
              location: {
                address1: values.address1,
                address2: values.address2,
                city: values.city,
                zip: values.zip,
                country: values.country
              }
            }
          }
        })
          .then((data) => {
            console.log('result from update operation', data);

            enqueueSnackbar('Your changes have been saved.', {
              variant: 'success'
            });
            // Swal.fire('Success!', 'Your profile has been updated!', 'success');
          })
          .catch((err) => {
            console.error('error', err);
          })
          .finally(() => {
            setWorkInProgress(false);
          });
      } else {
        //   gqlInsertUser({
        //     variables: {
        //       firstName: values.firstName,
        //       lastName: values.lastName,
        //       email: values.email,
        //       dateOfBirth: values.dateOfBirth,
        //       gender: values.gender,
        //       address1: values.address1,
        //       address2: values.address2,
        //       city: values.city,
        //       zip: values.zip,
        //       country: values.country,
        //       pic_url: values.pic_url,
        //     },
        //   })
        //     .then((data) => {
        //       console.log("result from update operation", data);
        //       setHasAthleteProfile(true);
        //       Swal.fire("Success!", "Your profile has been created!", "success");
        //     })
        //     .catch((err) => {
        //       console.error("error", err);
        //     });
      }
    }
  });

  const handleDeleteEventInstanceButtonClicked = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        gqlDeleteEventInstance({
          variables: {
            eventInstanceId: eventInstance._id
          }
        })
          .then((data) => {
            console.log('result from delete operation', data);
            onEventInstanceChanged && onEventInstanceChanged();
            Swal.fire(
              'Deleted!',
              'Your event instance has been deleted.',
              'success'
            );
          })
          .catch((err) => {
            console.error('error', err);
          });
      }
    });
  };

  // const handleSwitchChanged = async (event) => {
  //   formik.setFieldValue(event.target.name, event.target.checked);
  // };

  const handleDatePickerChanged = (fieldName, newValue) => {
    console.log(fieldName, newValue[0]);
    if (newValue.length > 0) {
      formik.setFieldValue(fieldName, newValue[0]);
    } else {
      console.error(
        'New picked date is an array with multiple date. Cannot update.'
      );
    }
  };

  const handleSelectMenuChanged = (fieldName, option) => {
    formik.setFieldValue(fieldName, option);
  };

  var decimalFormatter = new Intl.NumberFormat(i18n.language, {
    // style: "currency",
    // currency: event?.currency || "EUR",
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });

  // console.log('Event Instance Details: Data', data);

  if (!data) return <Loader />;

  const calculatedSystemFee = decimalFormatter.format(
    Number(process.env.REACT_APP_TDU_FIXED_FEE) +
      Number(
        (process.env.REACT_APP_TDU_PERCENTAGE_FEE / 100) *
          fm.un(formik.values?.registrationFee)
      )
  );

  return (
    <>
      <Card id={eventInstance?._id} sx={{ overflow: 'visible' }}>
        <ArgonBox
          p={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={handleExpandClick}
          style={{ cursor: 'pointer' }}>
          <ArgonTypography variant="h5" textTransform="capitalize">
            {formik.values?.type
              ? selectData.eventInstanceTypes.find(
                  (eit) => eit.value === formik.values.type
                ).label
              : 'Event Instance Details'}
          </ArgonTypography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </ArgonBox>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <ArgonBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%">
                  <ArgonBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      // textTransform="capitalize"
                    >
                      Event Instance Type
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonSelect
                    id="sports"
                    name="sports"
                    placeholder="Please choose"
                    options={selectData.eventInstanceTypes}
                    //value={formik.values.type}
                    value={selectData.eventInstanceTypes.filter(
                      (option) => option.value === formik.values.type
                    )}
                    onChange={(option) =>
                      handleSelectMenuChanged('type', option.value)
                    }
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  />
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormField
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  label="Name (optional)"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  placeholder="You can add a name / identifier for your event instance here"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormField
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  label="Description (optional)"
                  multiline
                  rows={4}
                  placeholder="You can add a desciption for your event instance here"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Table>
                  {/* Empty table head required to get rid of warning in dev tools */}

                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        Event Instance Parameters
                      </TableCell>
                      <TableCell
                        width="100%"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: '110px' }}>
                        {' '}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Is a virtual event
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Competitors will have to submit scores and videos
                            online
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <Switch
                          id="isVirtualEvent"
                          name="isVirtualEvent"
                          onChange={formik.handleChange}
                          checked={formik.values.isVirtualEvent}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Open registration
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Athletes can register themselves. If this is not
                            checked, you will have to manually invite
                            competitors (e.g. to a finale).
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell
                        align="end"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: '110px' }}>
                        <Switch
                          id="isOpenRegistration"
                          name="isOpenRegistration"
                          onChange={formik.handleChange}
                          checked={formik.values.isOpenRegistration}
                        />
                      </TableCell>
                    </TableRow>

                    {/* <TableRow>
                    <TableCell
                      padding={[1, 1, 1, 0.5]}
                      sx={{ minWidth: '110px' }}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          // variant="button"
                          // fontWeight="regular"
                          variant="caption"
                          fontWeight="bold">
                          Max # participants
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Set a limit of how many participants can register
                          across all divisions. Leave empty if there is no
                          limit.
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>

                    <TableCell
                      align="end"
                      padding={[1, 1, 1, 0.5]}
                      sx={{ minWidth: '110px' }}>
                      <ArgonInput
                        sx={{ minWidth: '110px' }}
                        id="participantLimit"
                        name="participantLimit"
                        label=""
                        placeholder=""
                        value={formik.values?.participantLimit}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.participantLimit &&
                          Boolean(formik.errors.participantLimit)
                        }
                        helperText={
                          formik.touched.participantLimit &&
                          formik.errors.participantLimit
                        }
                        size="small"
                        startAdornment={
                          <ArgonBox mr={1} ml={0}>
                            #
                          </ArgonBox>
                        }
                      />
                    </TableCell>
                  </TableRow> */}

                    <TableRow>
                      <TableCell padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            // variant="button"
                            // fontWeight="regular"
                            variant="caption"
                            fontWeight="bold">
                            Registration fee
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            You can set a fee for registering for this event.
                            You an also define an individual fee per disivion.
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>

                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        {/* <ArgonInput
                        sx={{ minWidth: '110px' }}
                        id="registrationFee"
                        name="registrationFee"
                        label=""
                        placeholder=""
                        // value={() =>
                        //   decimalFormatter.format(formik.values?.registrationFee)
                        // }
                        value={formik.values?.registrationFee}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          console.log('e', e);
                          formik.setFieldValue(
                            'registrationFee',
                            fm.un(e.target.value)
                          );
                        }}
                        // onBlur={() =>
                        //   formik.setFieldValue(
                        //     'registrationFee',
                        //     fm.un(formik.values?.registrationFee)
                        //   )
                        // }
                        error={
                          formik.touched.registrationFee &&
                          Boolean(formik.errors.registrationFee)
                        }
                        helperText={
                          formik.touched.registrationFee &&
                          formik.errors.registrationFee
                        }
                        size="small"
                        startAdornment={
                          <ArgonBox mr={1} ml={0}>
                            {event?.currency || 'EUR'}
                          </ArgonBox>
                        }
                      /> */}
                        <ArgonCurrencyInput
                          id="registrationFee"
                          name="registrationFee"
                          size="small"
                          // decimalSeparator=","
                          // groupSeparator="."
                          intlConfig={{
                            locale: i18n.language,
                            currency: event.currency || 'EUR'
                          }}
                          // prefix={currency || 'EUR' + ' '}
                          allowNegativeValue={false}
                          placeholder=""
                          defaultValue={0}
                          decimalsLimit={2}
                          fixedDecimalLength={2}
                          value={formik?.values?.registrationFee}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value, true);
                            // formik.setTouched(name, true);
                          }}
                          error={
                            formik.touched.registrationFee &&
                            Boolean(formik.errors.registrationFee)
                          }
                          sx={{ minWidth: '110px' }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            // variant="button"
                            // fontWeight="regular"
                            variant="caption"
                            fontWeight="bold">
                            System fee
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            This portion of the above defined fee will be paid
                            to Throwdown Universe; see our{' '}
                            <a href="pricing">pricing</a>
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>

                      <TableCell align="start" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox width="100%">
                          {/* <ArgonInput
                          size="small"
                          disabled
                          startAdornment={
                            <ArgonBox mr={1} ml={0}>
                              {event?.currency || 'EUR'}
                            </ArgonBox>
                          }
                          value={
                            formik.values?.registrationFee > 0
                              ? `${decimalFormatter.format(
                                  Number(process.env.REACT_APP_TDU_FIXED_FEE) +
                                    Number(
                                      (process.env
                                        .REACT_APP_TDU_PERCENTAGE_FEE /
                                        100) *
                                        formik.values?.registrationFee
                                    )
                                )}`
                              : `${event?.currency} ${decimalFormatter.format(
                                  Number(process.env.REACT_APP_TDU_FIXED_FEE)
                                )} `
                          }
                        /> */}
                          <ArgonCurrencyInput
                            id="systemFee"
                            name="systemFee"
                            size="small"
                            intlConfig={{
                              locale: i18n.language,
                              currency: event.currency || 'EUR'
                            }}
                            // prefix={currency || 'EUR' + ' '}
                            allowNegativeValue={false}
                            placeholder=""
                            defaultValue={0}
                            decimalsLimit={2}
                            value={calculatedSystemFee}
                            // onChange={formik.handleChange}
                            // onValueChange={(value, name) => {
                            //   console.log(value, name);
                            //   formik.setFieldValue(name, value, true);
                            //   // formik.setTouched(name, true);
                            // }}
                            error={
                              formik.touched.registrationFee &&
                              Boolean(formik.errors.registrationFee)
                            }
                            // transformRawValue={(value) => (value * 100).toString()}
                          />
                        </ArgonBox>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            // variant="button"
                            // fontWeight="regular"
                            variant="caption"
                            fontWeight="bold">
                            Registration deadline
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Date until which participants can register
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonDatePicker
                          id="registrationDeadline"
                          name="registrationDeadline"
                          input={{
                            size: 'small',
                            placeholder: 'Select a date',
                            error:
                              formik.touched.registrationDeadline &&
                              Boolean(formik.errors.registrationDeadline),

                            helperText:
                              formik.touched.registrationDeadline &&
                              formik.errors.registrationDeadline
                          }}
                          onChange={(date) => {
                            handleDatePickerChanged(
                              'registrationDeadline',
                              date
                            );
                          }}
                          value={formik.values.registrationDeadline}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            // variant="button"
                            // fontWeight="regular"
                            variant="caption"
                            fontWeight="bold">
                            Event start date{' '}
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular"></ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonDatePicker
                          id="startDate"
                          name="startDate"
                          // defaultValue={formik.values.startDate}
                          input={{
                            size: 'small',
                            placeholder: 'Select a date',
                            error:
                              formik.touched.startDate &&
                              Boolean(formik.errors.startDate),

                            helperText:
                              formik.touched.startDate &&
                              formik.errors.startDate
                          }}
                          onChange={(date) => {
                            handleDatePickerChanged('startDate', date);
                            if (
                              formik.values.endDate === '' ||
                              formik.values.endDate === null ||
                              formik.values.endDate === undefined
                            ) {
                              handleDatePickerChanged('endDate', date);
                            }
                          }}
                          value={formik.values.startDate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            // variant="button"
                            // fontWeight="regular"
                            variant="caption"
                            fontWeight="bold">
                            Event end date
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular"></ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonDatePicker
                          id="endDate"
                          name="endDate"
                          input={{
                            size: 'small',
                            placeholder: 'Select a date',
                            error:
                              formik.touched.endDate &&
                              Boolean(formik.errors.endDate),

                            helperText:
                              formik.touched.endDate && formik.errors.endDate
                          }}
                          // onChange={handleDatePickerChanged}
                          // onChange={(date) => formik.setFieldValue("date", date)}
                          onChange={(date) => {
                            handleDatePickerChanged('endDate', date);
                          }}
                          value={formik.values.endDate}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Table>
                  {/* Empty table head required to get rid of warning in dev tools */}

                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        Event Location
                      </TableCell>
                      <TableCell
                        width="100%"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: '110px' }}>
                        {' '}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Address Line 1
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            E.g. the event location name
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonInput
                          id="address1"
                          name="address1"
                          placeholder="Address Line 1"
                          size="small"
                          type="text"
                          value={formik.values.address1}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.address1 &&
                            Boolean(formik.errors.address1)
                          }
                          helperText={
                            formik.touched.address1 && formik.errors.address1
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Address Line 2
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            E.g. the street address
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonInput
                          id="address2"
                          name="address2"
                          placeholder="Address Line 2"
                          sx={{ minWidth: '250px' }}
                          size="small"
                          type="text"
                          value={formik.values.address2}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.address2 &&
                            Boolean(formik.errors.address2)
                          }
                          helperText={
                            formik.touched.address2 && formik.errors.address2
                          }
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Postal code
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Should be self explanatory
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonInput
                          id="zip"
                          name="zip"
                          placeholder="Postal Code"
                          sx={{ minWidth: '250px' }}
                          size="small"
                          type="text"
                          value={formik.values.zip}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.zip && Boolean(formik.errors.zip)
                          }
                          helperText={formik.touched.zip && formik.errors.zip}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            City
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Let your participants know where they need to go
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonInput
                          id="city"
                          name="city"
                          placeholder="City"
                          sx={{ minWidth: '250px' }}
                          size="small"
                          type="text"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.city && Boolean(formik.errors.city)
                          }
                          helperText={formik.touched.city && formik.errors.city}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Country
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Let your participants know where they need to go
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <ArgonSelect
                          id="country"
                          name="country"
                          size="small"
                          // overflow
                          placeholder="Please choose"
                          options={selectData.countries.sort((a, b) =>
                            a.label.localeCompare(b.label)
                          )}
                          //value={formik.values.type}
                          value={selectData.countries.filter(
                            (option) => option.value === formik.values?.country
                          )}
                          onChange={(option) =>
                            handleSelectMenuChanged('country', option.value)
                          }
                          error={
                            formik.touched.country &&
                            Boolean(formik.errors.country)
                          }
                          // helperText={formik.touched.country && formik.errors.country}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox display="flex" justifyContent="space-between">
                  <ArgonButton
                    variant="gradient"
                    color="info"
                    onClick={formik.submitForm}
                    loading={workInProgress}
                    style={{ width: '200px' }}>
                    Save&nbsp;
                    {formik.values?.type
                      ? selectData.eventInstanceTypes.find(
                          (eit) => eit.value === formik.values.type
                        ).label
                      : 'Event Instance Details'}
                  </ArgonButton>
                  <ArgonButton
                    variant="text"
                    color="error"
                    onClick={handleDeleteEventInstanceButtonClicked}>
                    <Icon>delete</Icon>&nbsp;Remove Event Instance
                  </ArgonButton>
                </ArgonBox>
              </Grid>
            </Grid>
          </ArgonBox>
        </Collapse>
      </Card>
      <ArgonBox mt={3}>
        <Card>
          <ArgonBox
            p={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={handleExpandClick}
            style={{ cursor: 'pointer' }}>
            <ArgonTypography variant="h5" textTransform="capitalize">
              {/* {formik.values?.type
                ? selectData.eventInstanceTypes.find(
                    (eit) => eit.value === formik.values.type
                  ).label
                : 'Event Instance Details'} */}
              Divisions
            </ArgonTypography>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </ArgonBox>
          <ArgonBox px={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <DivisionList eventInstance={eventInstance} event={event} />
              </Grid>
            </Grid>
          </ArgonBox>
        </Card>
      </ArgonBox>

      {/* <ArgonBox mt={3}>
        <SetupJudgingAndLeaderboard />
      </ArgonBox> */}

      {/* <ArgonBox mt={3}>
        <Card>
          <ArgonBox
            p={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={handleExpandClick}
            style={{ cursor: 'pointer' }}>
            <ArgonTypography variant="h5" textTransform="capitalize">
              
              Judging and Leaderboard
            </ArgonTypography>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </ArgonBox>
          <ArgonBox px={3} mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Table>


                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        Event Instance Parameters
                      </TableCell>
                      <TableCell
                        width="100%"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: '110px' }}>
                        {' '}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Is a virtual event
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Competitors will have to submit scores and videos
                            online
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell align="end" padding={[1, 1, 1, 0.5]}>
                        <Switch
                          id="isVirtualEvent"
                          name="isVirtualEvent"
                          onChange={formik.handleChange}
                          checked={formik.values.isVirtualEvent}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                        <ArgonBox lineHeight={1.4}>
                          <ArgonTypography
                            display="block"
                            variant="caption"
                            fontWeight="bold">
                            Open registration
                          </ArgonTypography>
                          <ArgonTypography
                            variant="caption"
                            color="text"
                            fontWeight="regular">
                            Athletes can register themselves. If this is not
                            checked, you will have to manually invite
                            competitors (e.g. to a finale).
                          </ArgonTypography>
                        </ArgonBox>
                      </TableCell>
                      <TableCell
                        align="end"
                        padding={[1, 1, 1, 0.5]}
                        sx={{ minWidth: '110px' }}>
                        <Switch
                          id="isOpenRegistration"
                          name="isOpenRegistration"
                          onChange={formik.handleChange}
                          checked={formik.values.isOpenRegistration}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </ArgonBox>
        </Card>
      </ArgonBox> */}
    </>
  );
}

export default EventInstanceDetails;
