import axios from 'axios';
import { getSession, refreshIdToken } from './cognito/cognitoAccount';

// import { useQuery, useMutation, gql } from "@apollo/client";
// import { EVENT_GET_BY_ID } from "GraphQL/Events/queries";
// import { EVENT_DIVISION_CREATE, EVENT_UPDATE } from "GraphQL/Events/mutations";

export const registerToEventInstance = (eventData) =>
  new Promise(async (resolve, reject) => {
    try {
      const session = await getSession().catch((err) => {
        console.error(err);
        reject(err);
      });
      // console.log('Session for sending new registration to backend:', session);

      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URI}/v1/event/register`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(eventData)
      };

      console.log('Now sending new event registration to backend...');
      axios(config)
        .then(async function (response) {
          console.log(JSON.stringify(response.data));
          console.log(
            'Now refreshing id token to update cognito roles for new event...'
          );
          const refreshedSession = await refreshIdToken().catch((err) => {
            console.error(err);
            reject(err);
          });
          // console.log('Refreshed session:', refreshedSession);
          resolve(response.data);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });

export const updateParticipant = (updatedData) =>
  new Promise(async (resolve, reject) => {
    try {
      const session = await getSession().catch((err) => {
        console.error(err);
        reject(err);
      });
      console.log('Session for sending new registration to backend:', session);

      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URI}/v1/registration/update-participant`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(updatedData)
      };

      console.log('Now sending updated participant data to backend...');
      axios(config)
        .then(async function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });

export const withdrawRegistration = (registrationId) =>
  new Promise(async (resolve, reject) => {
    try {
      const session = await getSession().catch((err) => {
        console.error(err);
        reject(err);
      });
      console.log('Session for sending new registration to backend:', session);

      const config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URI}/v1/registration/withdraw`,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ registrationId: registrationId })
      };

      console.log('Now sendingrequest for registration withdrawal to backend');
      axios(config)
        .then(async function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });

export const createEvent = (eventData) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession().catch((err) => {
      console.error(err);
      reject(err);
    });
    console.log('Session for sending new event to backend:', session);

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/event/create`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(eventData)
    };

    console.log('Now sending new event to backend...');
    axios(config)
      .then(async function (response) {
        console.log(JSON.stringify(response.data));
        console.log(
          'Now refreshing id token to update cognito roles for new event...'
        );
        const refreshedSession = await refreshIdToken().catch((err) => {
          console.error(err);
          reject(err);
        });
        console.log('Refreshed session:', refreshedSession);

        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const deleteEvent = (eventId) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/event/delete`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ eventId: eventId })
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const createEventInstanceDivision = (params) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/division/create`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(params)
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const createNewOrder = (params) =>
  new Promise(async (resolve, reject) => {
    const { organizationId } = params;
    if (!organizationId) throw new Error('No organizationId provided');

    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/order/create`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(params)
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

// export const updateEvent = (eventId, eventObject) =>
//   new Promise(async (resolve, reject) => {
//     const [gqlUpdateEvent] = useMutation(EVENT_UPDATE);
//     gqlUpdateEvent({
//       variables: {
//         eventId: eventId,
//         data: eventObject,
//       },
//     })
//       .then((result) => {
//         resolve();
//       })
//       .catch((error) => {
//         console.error(error);
//         reject(error);
//       });
//   });

// export const createDivision = (eventId, divisionData) =>
//   new Promise(async (resolve, reject) => {
//     const [gqlCreateDivision] = useMutation(EVENT_DIVISION_CREATE, {
//       refetchQueries: [
//         {
//           query: EVENT_GET_BY_ID,
//           variables: { eventId: eventId },
//         },
//       ],
//     });

//     gqlCreateDivision({
//       variables: {
//         data: {
//           eventId: eventId,
//           division: divisionData,
//         },
//       },
//     })
//       .then((result) => {
//         resolve();
//       })
//       .catch((error) => {
//         console.error(error);
//         reject(error);
//       });
//   });

// create payment intent with secret key
export const stripeCreateCheckoutSession = async (orderId) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/payments/create-checkout-session`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ orderId })
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

// create payment intent with secret key
export const stripeCreatePaymentIntent = async (
  registrationId,
  productIdArray
) =>
  new Promise(async (resolve, reject) => {
    console.log('Registration ID for creating payment intent:', registrationId);

    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/payments/create-payment-intent`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ registrationId, productIds: productIdArray })
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const stripeGetPublishableKey = async () =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/payments/get-publishable-key`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({})
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

//   fetch(
//     `${process.env.REACT_APP_API_URI}/v1/payments/get-publishable-key`,

//     {
//       headers: {
//         Authentication: `Bearer ${idToken}`,
//       },
//       method: "POST",
//       body: JSON.stringify({}),
//     }
//   ).then(async (result) => {
//     const { publishableKey } = await result.json();
//     return publishableKey;
//   });
// };

export const logEventView = (eventId) =>
  new Promise(async (resolve, reject) => {
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/event/views/log-view`,

      data: JSON.stringify({ eventId: eventId })
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });

export const checkVatId = (vatId) =>
  new Promise(async (resolve, reject) => {
    console.log('Testing VAT ID:', vatId);

    const countryCode = vatId.substring(0, 2);
    const vatNumber = vatId.substring(2);

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/organization/check-vat-id`,

      data: JSON.stringify({ countryCode, vatNumber })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const createNewOrganization = ({
  name,
  type,
  adress1,
  adress2,
  zip,
  city,
  country,
  email,
  phone,
  website,
  vatId
}) =>
  new Promise(async (resolve, reject) => {
    console.log('Testing VAT ID:', vatId);

    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/organization/onboarding/create-organization`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        name,
        type,
        adress1,
        adress2,
        zip,
        city,
        country,
        email,
        phone,
        website,
        vatId
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const createNewStripeAccount = ({
  organizationId,
  email,
  country,
  businessType,
  organizationName,
  adress1,
  adress2,
  vatId
}) =>
  new Promise(async (resolve, reject) => {
    console.log('Testing VAT ID:', vatId);

    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/organization/onboarding/create-stripe-account`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        organizationId,
        email,
        country,
        businessType,
        organizationName,
        adress1,
        adress2,
        vatId,
        return_Url: window.location.href
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const postNewScore = ({
  workoutId,
  eventId,
  eventInstanceId,
  postedScore,
  // divisionId,
  registrationId,
  videoUrl
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/scoring/online-qualifier/post-score`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        workoutId: workoutId,
        eventId: eventId,
        eventInstanceId: eventInstanceId,
        postedScore,
        // division: divisionId,
        registrationId: registrationId,
        videoUrl: videoUrl
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const updatePostedScore = ({ scoreId, newScore }) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/scoring/online-qualifier/update-posted-score`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        scoreId,
        postedScore: newScore
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const postJudgedScore = ({
  scoreId,
  judgedScore,
  judgingNotes,
  judgingPenalties
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/scoring/online-qualifier/judge-score`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        scoreId,
        judgedScore,
        judgingNotes,
        judgingPenalties
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const removeJudgedScore = ({ scoreId, newStatus }) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/scoring/online-qualifier/remove-judged-score`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        scoreId,
        newStatus
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getScoreVideoDownloadLink = (scoreId) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    // var config = {
    //   method: 'get',
    //   url: `${process.env.REACT_APP_API_URI}/v1/scoring/video/get-download-url/${scoreId}`,
    //   headers: {
    //     Authorization: `Bearer ${session.idToken.jwtToken}`,
    //     'Content-Type': 'application/json'
    //   }
    // };

    // axios(config)
    //   .then(function (response) {
    //     console.log('data from axios request', response.data);
    //     resolve(response.data);
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //     reject(error);
    //   });

    axios
      .get(
        `${process.env.REACT_APP_API_URI}/v1/scoring/online-qualifier/video/get-download-url/${scoreId}`,
        {
          headers: {
            Authorization: `Bearer ${session.idToken.jwtToken}`
          }
        }
      )
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const onSiteEventPostScore = ({
  workoutId,
  eventId,
  eventInstanceId,
  postedScore,
  postedBy,
  // divisionId,
  registrationId,
  videoUrl
}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/scoring/on-site/post-score`,
      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        workoutId: workoutId,
        eventId: eventId,
        eventInstanceId: eventInstanceId,
        postedScore,
        postedBy,
        // division: divisionId,
        registrationId: registrationId,
        videoUrl: videoUrl
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getLeaderboardColumns = ({ eventInstanceId }) =>
  new Promise(async (resolve, reject) => {
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URI}/v1/scoring/leaderboard/get-columns/${eventInstanceId}`,
      headers: {
        'Content-Type': 'application/json'
      }
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const getLeaderboardData = ({
  eventInstanceId,
  divisionId,
  pageOffset,
  pageSize
}) =>
  new Promise(async (resolve, reject) => {
    console.log('Rest API divisionID receved', divisionId);

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URI}/v1/scoring/leaderboard/get-data`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        eventInstanceId,
        divisionId,
        pageOffset,
        pageSize
      })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
