/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI base styles
import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';

// Argon Dashboard 2 PRO MUI helper functions
import pxToRem from 'assets/theme/functions/pxToRem';
import boxShadow from 'assets/theme/functions/boxShadow';

const { dark, white, info, success } = colors;
const { borderWidth, borderColor } = borders;

const stepIcon = {
  styleOverrides: {
    root: {
      background: white.main,
      fill: white.main,
      stroke: white.main,
      strokeWidth: pxToRem(10),
      width: pxToRem(13),
      height: pxToRem(13),
      border: `${borderWidth[2]} solid ${borderColor}`,
      borderRadius: '50%',
      zIndex: 99,
      transition: 'all 200ms linear',

      '&.Mui-active': {
        background: info.main,
        fill: info.main,
        stroke: info.main,
        borderColor: info.main,
        boxShadow: boxShadow([0, 0], [0, 2], dark.main, 1)
      },

      '&.Mui-completed': {
        background: success.main,
        // fill: dark.main,
        fill: success.main,
        stroke: success.main,
        borderColor: success.main,
        boxShadow: boxShadow([0, 0], [0, 2], dark.main, 1)
      }
    }
  }
};

export default stepIcon;
