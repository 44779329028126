/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonButton from 'components/ArgonButton';

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from 'context';
// import { ExpandMore } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Switch,
} from '@mui/material';
import { styled } from '@mui/styles';
import FormField from 'layouts/pages/account/components/FormField';
import CreateDivisionDialog from './CreateDivisionDialog';

import { useMutation } from '@apollo/client';
import { EVENT_DIVISION_DELETE } from 'GraphQL/Events/mutations';
import Swal from 'sweetalert2';
import { EVENT_GET_BY_ID } from 'GraphQL/Events/queries';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function DivisionSummaryCard({ division, noGutter, edit, currency, eventId }) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const [expanded, setExpanded] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openDuplicateDialog, setOpenDuplicateDialog] = React.useState(false);
  const [duplicateDivision, setDuplicateDivision] = React.useState({});

  const [gqlDeleteDivision] = useMutation(EVENT_DIVISION_DELETE, {
    refetchQueries: [
      {
        query: EVENT_GET_BY_ID,
        variables: { eventId: eventId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDuplicateDivisionButton = (event) => {
    event.preventDefault();
    const dupe = { ...division, _id: undefined };
    setDuplicateDivision(dupe);
    setOpenDuplicateDialog(true);
  };

  const handleDeleteDivisionButton = (event) => {
    event.preventDefault();
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteDivision();
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    });
  };

  const deleteDivision = async () => {
    await gqlDeleteDivision({
      variables: {
        data: {
          eventId: eventId,
          divisionId: division._id,
        },
      },
    });
  };

  return (
    <>
      <ArgonBox
        component='li'
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
        borderRadius='lg'
        pl={3}
        pr={3}
        mb={noGutter ? 0 : 1}
        mt={2}
        sx={({ palette: { grey, background } }) => ({
          backgroundColor: darkMode ? background.default : grey[100],
        })}
      >
        <ArgonBox width='100%' display='flex' flexDirection='column'>
          <ArgonBox
            display='flex'
            justifyContent='space-between'
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
            mb={1}
            mt={1}
            onClick={handleExpandClick}
            style={{ cursor: 'pointer' }}
          >
            <ArgonTypography
              variant='button'
              fontWeight='medium'
              textTransform='capitalize'
            >
              {division?.name}
            </ArgonTypography>

            <ArgonBox
              display='flex'
              alignItems='center'
              mt={{ xs: 2, sm: 0 }}
              ml={{ xs: -1.5, sm: 0 }}
            >
              {/* <ArgonBox mr={1}>
              <ArgonButton variant="text" color="error">
                <Icon>delete</Icon>&nbsp;Delete
              </ArgonButton>
            </ArgonBox> */}
              {/* <ArgonButton variant="text" color="dark">
              <Icon>edit</Icon>
            </ArgonButton> */}

              {/* <ArgonButton variant="text" color="dark"> */}
              {/* <Icon>info</Icon> */}
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label='show more'
              >
                <ExpandMoreIcon />
              </ExpandMore>
              {/* </ArgonButton> */}
            </ArgonBox>
          </ArgonBox>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container spacing={0} mt={{ xs: 1 }}>
              <Grid item xs={12}>
                <ArgonBox mb={1} lineHeight={0} width='100%'>
                  <ArgonTypography variant='caption' fontWeight='medium'>
                    Description:&nbsp;&nbsp;&nbsp;
                  </ArgonTypography>
                </ArgonBox>

                <ArgonBox mb={1} lineHeight={0} width='100%'>
                  <ArgonTypography
                    variant='caption'
                    fontWeight='medium'
                    color='text'
                  >
                    {division?.description}
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
              <ArgonBox mb={1} lineHeight={0}>
                <ArgonTypography variant="caption" color="text">
                  {division.forTeams
                    ? "Max # of teams"
                    : "Max # of participants"}

                  <ArgonTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {division?.maxParticipants}
                  </ArgonTypography>
                </ArgonTypography>
              </ArgonBox>
            </Grid> */}

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant='caption' fontWeight='medium'>
                      For individuals:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox lineHeight={0} mx={2}>
                      <ArgonTypography variant='caption' color='text'>
                        {division.forIndividuals ? 'Yes' : 'No'}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mr={1} alignItems='center'>
                      <Switch
                        readOnly
                        name='forIndividuals'
                        checked={division.forIndividuals}
                        // onChange={handleSwitchChange}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant='caption'
                      // color="text"
                      fontWeight='medium'
                    >
                      For underage:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox lineHeight={0} mx={2}>
                      <ArgonTypography
                        variant='caption'
                        color='text'
                        // fontWeight="medium"
                      >
                        {division?.forUnderage ? 'Yes' : 'No'}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mr={1} alignItems='center'>
                      <Switch
                        readOnly
                        name='forUnderage'
                        checked={division?.forUnderage}
                        // onChange={handleSwitchChange}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant='caption' fontWeight='medium'>
                      For teams:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox lineHeight={0} mx={2}>
                      <ArgonTypography
                        variant='caption'
                        color='text'
                        // fontWeight="medium"
                      >
                        {division?.forTeams ? 'Yes' : 'No'}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mr={1} alignItems='center'>
                      <Switch
                        readOnly
                        name='forUnderage'
                        checked={division?.forTeams}
                        // onChange={handleSwitchChange}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
              {division?.forTeams && (
                <Grid item xs={12} sm={12}>
                  <ArgonBox
                    display='flex'
                    alignItems='center'
                    width={{ xs: '100%' }}
                    justifyContent='space-between'
                  >
                    {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                    <ArgonBox
                    // lineHeight={1.4}
                    >
                      <ArgonTypography variant='caption' fontWeight='medium'>
                        Teamsize:
                      </ArgonTypography>
                    </ArgonBox>

                    <ArgonBox
                      display='flex'
                      // justifyContent="flex-end"
                      alignItems='center'
                      width={{ xs: '100%', sm: 'auto' }}
                      mt={{ xs: 1, sm: 0 }}
                    >
                      <ArgonBox mr={1} alignItems='center'>
                        {edit ? (
                          <FormField
                            readOnly
                            id='maxParticipants'
                            name='maxParticipants'
                            value={division?.maxParticipants}
                            style={{ maxWidth: '50px' }}
                            // onChange={formik.handleChange}
                            // error={formik.touched.name && Boolean(formik.errors.name)}
                            // helperText={formik.touched.name && formik.errors.name}
                          />
                        ) : (
                          <ArgonTypography
                            variant='caption'
                            color='text'
                            // fontWeight="medium"
                          >
                            {division?.maxParticipants || 'no limit set'}
                          </ArgonTypography>
                        )}
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
              )}

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant='caption' fontWeight='medium'>
                      {division.forTeams
                        ? 'Max # of teams'
                        : 'Max # of participants'}
                      :
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox mr={1} alignItems='center'>
                      {edit ? (
                        <FormField
                          readOnly
                          id='maxParticipants'
                          name='maxParticipants'
                          value={division?.maxParticipants}
                          style={{ maxWidth: '50px' }}
                          // onChange={formik.handleChange}
                          // error={formik.touched.name && Boolean(formik.errors.name)}
                          // helperText={formik.touched.name && formik.errors.name}
                        />
                      ) : (
                        <ArgonTypography
                          variant='caption'
                          color='text'
                          // fontWeight="medium"
                        >
                          {division?.maxParticipants || 'no limit set'}
                        </ArgonTypography>
                      )}
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant='caption' fontWeight='medium'>
                      Online qualifier registration fee:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox mr={1} alignItems='center'>
                      {edit ? (
                        <FormField
                          readOnly
                          id='onlineQualifierRegistrationFee'
                          name='onlineQualifierRegistrationFee'
                          value={division?.onlineQualifierRegistrationFee}
                          style={{ maxWidth: '50px' }}
                          // onChange={formik.handleChange}
                          // error={formik.touched.name && Boolean(formik.errors.name)}
                          // helperText={formik.touched.name && formik.errors.name}
                        />
                      ) : (
                        <ArgonTypography
                          variant='caption'
                          color='text'
                          // fontWeight="medium"
                        >
                          {currency && currency}&nbsp;
                          {division?.onlineQualifierRegistrationFee || '0'}
                        </ArgonTypography>
                      )}
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant='caption'
                      // color="text"
                      fontWeight='medium'
                    >
                      Final registration fee:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox mr={1} alignItems='center'>
                      {edit ? (
                        <FormField
                          readOnly
                          id='finalRegistrationFee'
                          name='finalRegistrationFee'
                          value={division?.finalRegistrationFee}
                          style={{ maxWidth: '50px' }}
                          // onChange={formik.handleChange}
                          // error={formik.touched.name && Boolean(formik.errors.name)}
                          // helperText={formik.touched.name && formik.errors.name}
                        />
                      ) : (
                        <ArgonTypography
                          variant='caption'
                          color='text'
                          // fontWeight="medium"
                        >
                          {currency && currency}&nbsp;
                          {division?.finalRegistrationFee || '0'}
                        </ArgonTypography>
                      )}
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant='caption'
                      // color="text"
                      fontWeight='medium'
                    >
                      Minimum age of competitior (years):
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox mr={1} alignItems='center'>
                      {edit ? (
                        <FormField
                          readOnly
                          id='minAge'
                          name='minAge'
                          value={division?.minAge}
                          style={{ maxWidth: '50px' }}
                          // onChange={formik.handleChange}
                          // error={formik.touched.name && Boolean(formik.errors.name)}
                          // helperText={formik.touched.name && formik.errors.name}
                        />
                      ) : (
                        <ArgonTypography
                          variant='caption'
                          color='text'
                          // fontWeight="medium"
                        >
                          {division?.minAge || 'not limited'}
                        </ArgonTypography>
                      )}
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant='caption'
                      // color="text"
                      fontWeight='medium'
                    >
                      Maximum age of competitor (years):
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox mr={1} alignItems='center'>
                      {edit ? (
                        <FormField
                          readOnly
                          id='maxAge'
                          name='maxAge'
                          value={division?.maxAge}
                          style={{ maxWidth: '50px' }}
                          // onChange={formik.handleChange}
                          // error={formik.touched.name && Boolean(formik.errors.name)}
                          // helperText={formik.touched.name && formik.errors.name}
                        />
                      ) : (
                        <ArgonTypography
                          variant='caption'
                          color='text'
                          // fontWeight="medium"
                        >
                          {division?.maxAge || 'not limited'}
                        </ArgonTypography>
                      )}
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <ArgonBox display='flex' justifyContent='end'>
              <ArgonBox mr={0} mb={1}>
                <ArgonButton
                  variant='text'
                  color='info'
                  onClick={handleDuplicateDivisionButton}
                >
                  <Icon>add</Icon>&nbsp;Duplicate
                </ArgonButton>
                <ArgonButton
                  variant='text'
                  color='info'
                  onClick={() => setOpenEditDialog(true)}
                >
                  <Icon>edit</Icon>&nbsp;Edit
                </ArgonButton>
                <ArgonButton
                  variant='text'
                  color='error'
                  onClick={handleDeleteDivisionButton}
                >
                  <Icon>delete</Icon>&nbsp;Delete
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </Collapse>
        </ArgonBox>
      </ArgonBox>
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <CreateDivisionDialog
          eventId={eventId}
          division={division}
          onClose={() => setOpenEditDialog(false)}
        />
      </Dialog>

      <Dialog
        open={openDuplicateDialog}
        onClose={() => setOpenDuplicateDialog(false)}
      >
        <CreateDivisionDialog
          eventId={eventId}
          // division={duplicateDivision}
          division={{ ...division, _id: undefined }}
          onClose={() => setOpenDuplicateDialog(false)}
        />
      </Dialog>
    </>
  );
}

// Setting default values for the props of Bill
DivisionSummaryCard.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
DivisionSummaryCard.propTypes = {
  division: PropTypes.object.isRequired,
  noGutter: PropTypes.bool,
};

export default DivisionSummaryCard;
