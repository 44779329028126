/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-quill components
import ReactQuill from 'react-quill';

// react-quill styles
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from './EditorToolbar';
// Custom styles for the ArgonEditor
import ArgonEditorRoot from 'components/ArgonEditor/ArgonEditorRoot';
import ObjectID from 'bson-objectid';
import { useState } from 'react';

String.prototype.hashCode = function () {
  var hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

function ArgonEditor({ uniqueKey, ...rest }) {
  // const [key, setKey] = useState(
  //   'toolbar_' + props?.movements?.hashCode() + '_' + ObjectID().toHexString()
  // );

  console.log('Key', uniqueKey);

  return (
    <ArgonEditorRoot>
      {/* <EditorToolbar className={uniqueKey} /> */}
      <ReactQuill
        // className={uniqueKey}
        theme='snow'
        {...rest}
        // modules={modules}
        // formats={formats}
      />
    </ArgonEditorRoot>
  );
}

export default ArgonEditor;
