const { gql } = require('@apollo/client');

export const REGISTRATIONS = gql`
  query Registrations($data: RegistrationQueryInput) {
    registrations(query: $data) {
      _id
      createdBy
      status
      event {
        _id
        creationDate
        currency
        descr_long
        descr_short
        forIndividuals
        forTeams
        forUnderage
        hasOnlineQualifier
        name
        organization {
          _id
          address1
          address2
          city
          country
          createdBy
          creationDate
          email
          logo_url
          name
          state
          stripeAccountId
          stripeChargesEnabled
          taxId
          website
          zip
        }
        participantLimit
        participationFee
        pic_logo_url
        sports
        type
        noAskForPlatformFee
      }
      eventInstance {
        _id
        creationDate
        currency
        description
        endDate
        isOpenRegistration
        isVirtualEvent
        maxParticipants
        name
        organizationId
        registrationDeadline
        registrationFee
        startDate
        type
        divisions {
          _id
          description
          forIndividuals
          forTeams
          forUnderage
          maxAge
          maxParticipants
          minAge
          name
          registrationFee
          teamSize
          advancingAthletesNumber
          advancingAthletesPercentage
          assignedJudges
        }
        location {
          address1
          address2
          zip
          city
          country
          state
        }
      }
      divisionId
      agreeOrganizerTerms
      agreeToS
      athletes {
        _id
        affiliate
        firstName
        lastName
        dateOfBirth
        isRegisteredUser
        user
        country
        email
      }
      amountDue
      systemFee
      teamName
      affiliate
      paymentLog
      paymentStatus
      paymentReference
      registrationDate
      requiredTeamSize

      participants {
        _id
        firstName
        lastName
        dateOfBirth
        gender
        teamName
        email
        affiliate
        division
        divisionName
      }
    }
  }
`;

export const REGISTRATIONS_GET_ALL = gql`
  query {
    registrations(sortBy: REGISTRATIONDATE_DESC) {
      _id
      createdBy
      status
      event {
        _id
        creationDate
        currency
        descr_long
        descr_short
        forIndividuals
        forTeams
        forUnderage
        hasOnlineQualifier
        name

        organization {
          _id
          address1
          address2
          city
          country
          createdBy
          creationDate
          email
          logo_url
          name
          state
          stripeAccountId
          stripeChargesEnabled
          taxId
          website
          zip
        }
        participantLimit
        participationFee
        pic_logo_url
        sports
        type
        noAskForPlatformFee
      }
      eventInstance {
        _id
        creationDate
        currency
        description
        endDate
        isOpenRegistration
        isVirtualEvent
        maxParticipants
        name
        organizationId
        registrationDeadline
        registrationFee
        startDate
        type
        divisions {
          _id
          description
          forIndividuals
          forTeams
          forUnderage
          maxAge
          maxParticipants
          minAge
          name
          registrationFee
          teamSize
          advancingAthletesNumber
          advancingAthletesPercentage
          assignedJudges
        }
        location {
          address1
          address2
          zip
          city
          country
          state
        }
      }
      divisionId
      agreeOrganizerTerms
      agreeToS
      athletes {
        _id
        affiliate
        firstName
        lastName
        dateOfBirth
        isRegisteredUser
        user
        country
        email
      }
      amountDue
      systemFee
      teamName
      affiliate
      paymentLog
      paymentStatus
      paymentReference
      registrationDate
      requiredTeamSize

      participants {
        _id
        firstName
        lastName
        dateOfBirth
        gender
        teamName
        email
        affiliate
        division
        divisionName
      }
    }
  }
`;

// currently not required as REGISTRATIONS_GET_ALL returns all registrations, either where user is creator or athlete
export const REGISTRATIONS_GET_WHERE_PARTICIPATING = gql`
  query getAthleteRegistrationsByEmail(
    $data: GetAthleteRegistrationsByEmailInput
  ) {
    GetAthleteRegistrationsByEmail(input: $data) {
      registrations {
        _id
        createdBy
        status
        event {
          _id
          creationDate
          currency

          descr_long
          descr_short
          forIndividuals
          forTeams
          forUnderage
          hasOnlineQualifier
          name

          organization {
            _id
            address1
            address2
            city
            country
            createdBy
            creationDate
            email
            logo_url
            name
            state
            stripeAccountId
            stripeChargesEnabled
            taxId
            website
            zip
          }

          participantLimit
          participationFee
          pic_logo_url
          sports
          type
        }
        eventInstance {
          _id
          creationDate
          currency
          description
          endDate
          isOpenRegistration
          isVirtualEvent
          maxParticipants
          name
          organizationId
          registrationDeadline
          registrationFee
          startDate
          type
          divisions {
            _id
            description
            forIndividuals
            forTeams
            forUnderage
            maxAge
            maxParticipants
            minAge
            name
            registrationFee
            teamSize
            advancingAthletesNumber
            advancingAthletesPercentage
            assignedJudges
          }
        }
        divisionId
        agreeOrganizerTerms
        agreeToS
        athletes {
          _id
          affiliate
          firstName
          lastName
          dateOfBirth
          isRegisteredUser
          user
          country
          email
        }
        amountDue
        systemFee
        teamName
        affiliate
        paymentLog
        paymentStatus
        paymentReference
        registrationDate
        requiredTeamSize
      }

      participants {
        _id
        firstName
        lastName
        dateOfBirth
        gender
        teamName
        email
        affiliate
        division
        divisionName
      }
    }
  }
`;

export const REGISTRATION_GET_BY_ID = gql`
  query getRegistrationById($id: ObjectId!) {
    registration(query: { _id: $id }) {
      _id
      createdBy
      status
      event {
        _id
        creationDate
        currency
        descr_long
        descr_short
        forIndividuals
        forTeams
        forUnderage
        hasOnlineQualifier
        name

        organization {
          _id
          address1
          address2
          city
          country
          createdBy
          creationDate
          email
          logo_url
          name
          state
          stripeAccountId
          stripeChargesEnabled
          taxId
          website
          zip
        }
        participantLimit
        participationFee
        pic_logo_url
        sports
        type
        noAskForPlatformFee
        eventIsVisible
      }
      eventInstance {
        _id
        creationDate
        currency
        description
        endDate
        isOpenRegistration
        isVirtualEvent
        maxParticipants
        name
        organizationId
        registrationDeadline
        registrationFee
        startDate
        type
        divisions {
          _id
          description
          forIndividuals
          forTeams
          forUnderage
          maxAge
          maxParticipants
          minAge
          name
          registrationFee
          teamSize
          advancingAthletesNumber
          advancingAthletesPercentage
          assignedJudges
        }
      }
      divisionId
      agreeOrganizerTerms
      agreeToS
      athletes {
        _id
        affiliate
        firstName
        lastName
        dateOfBirth
        isRegisteredUser
        user
        country
        email
      }
      amountDue
      systemFee
      teamName
      affiliate
      paymentLog
      paymentStatus
      paymentReference
      registrationDate
      requiredTeamSize

      participants {
        _id
        firstName
        lastName
        dateOfBirth
        gender
        teamName
        email
        affiliate
        division
        divisionName
      }
    }
  }
`;

export const REGISTRATIONS_GET_BY_EVENTINSTANCEID = gql`
  query getRegistrationsForEventInstance(
    $eventInstance: EventInstanceQueryInput!
  ) {
    registrations(query: { eventInstance: $eventInstance }) {
      _id
      createdBy
      status
      event {
        _id
        creationDate
        currency
        descr_long
        descr_short
        forIndividuals
        forTeams
        forUnderage
        hasOnlineQualifier
        name

        organization {
          _id
          address1
          address2
          city
          country
          createdBy
          creationDate
          email
          logo_url
          name
          state
          stripeAccountId
          stripeChargesEnabled
          taxId
          website
          zip
        }
        participantLimit
        participationFee
        pic_logo_url
        sports
        type
        noAskForPlatformFee
        eventIsVisible
      }
      eventInstance {
        _id
        creationDate
        currency
        description
        endDate
        isOpenRegistration
        isVirtualEvent
        maxParticipants
        name
        organizationId
        registrationDeadline
        registrationFee
        startDate
        type
        divisions {
          _id
          description
          forIndividuals
          forTeams
          forUnderage
          maxAge
          maxParticipants
          minAge
          name
          registrationFee
          teamSize
          advancingAthletesNumber
          advancingAthletesPercentage
          assignedJudges
        }
      }
      divisionId
      agreeOrganizerTerms
      agreeToS
      athletes {
        _id
        affiliate
        firstName
        lastName
        dateOfBirth
        isRegisteredUser
        user
        country
        email
      }
      amountDue
      systemFee
      teamName
      affiliate
      paymentLog
      paymentStatus
      paymentReference
      registrationDate
      requiredTeamSize

      participants {
        _id
        firstName
        lastName
        dateOfBirth
        gender
        teamName
        email
        affiliate
        division
        divisionName
      }
    }
  }
`;

export const REGISTRATIONS_GET_BY_EVENTID = gql`
  query getRegistrationsForEvent($event: EventQueryInput!) {
    registrations(query: { event: $event }) {
      _id
      createdBy
      status
      event {
        _id
        creationDate
        currency
        descr_long
        descr_short
        forIndividuals
        forTeams
        forUnderage
        hasOnlineQualifier
        name

        organization {
          _id
          address1
          address2
          city
          country
          createdBy
          creationDate
          email
          logo_url
          name
          state
          stripeAccountId
          stripeChargesEnabled
          taxId
          website
          zip
        }
        participantLimit
        participationFee
        pic_logo_url
        sports
        type
        noAskForPlatformFee
        eventIsVisible
      }
      eventInstance {
        _id
        creationDate
        currency
        description
        endDate
        isOpenRegistration
        isVirtualEvent
        maxParticipants
        name
        organizationId
        registrationDeadline
        registrationFee
        startDate
        type
        divisions {
          _id
          description
          forIndividuals
          forTeams
          forUnderage
          maxAge
          maxParticipants
          minAge
          name
          registrationFee
          teamSize
          advancingAthletesNumber
          advancingAthletesPercentage
          assignedJudges
        }
      }
      divisionId
      agreeOrganizerTerms
      agreeToS
      athletes {
        _id
        affiliate
        firstName
        lastName
        dateOfBirth
        isRegisteredUser
        user
        country
        email
      }
      amountDue
      systemFee
      teamName
      affiliate
      paymentLog
      paymentStatus
      paymentReference
      registrationDate
      requiredTeamSize

      participants {
        _id
        firstName
        lastName
        dateOfBirth
        gender
        teamName
        email
        affiliate
        division
        divisionName
      }
    }
  }
`;
