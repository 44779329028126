const { gql } = require('@apollo/client');

export const EVENTINSTANCE_GET_BY_ID = gql`
  query getEventInstanceById($eventInstanceId: ObjectId!) {
    eventInstance(query: { _id: $eventInstanceId }) {
      _id
      creationDate
      event {
        _id
        creationDate
        currency
        date_end
        date_start
        descr_long
        descr_short
        forIndividuals
        forTeams
        forUnderage
        hasOnlineQualifier
        name
        participantLimit
        participationFee
        pic_logo_url
        sports
        type
        noAskForPlatformFee
        eventIsVisible

        organization {
          _id
          address1
          address2
          city
          country
          createdBy
          creationDate
          email
          logo_url
          name
          state
          stripeAccountId
          stripeChargesEnabled
          taxId
          website
          zip
        }
      }
      registrationDeadline
      startDate
      endDate
      description
      organizationId
      type
      isOpenRegistration
      name
      maxParticipants
      registrationFee
      isVirtualEvent
      divisions {
        _id
        name
        description
        maxParticipants
        forIndividuals
        forTeams
        forUnderage
        teamSize
        minAge
        maxAge
        registrationFee
        advancingAthletesNumber
        advancingAthletesPercentage
        assignedJudges
      }
      location {
        address1
        address2
        zip
        city
        country
        state
      }
    }
  }
`;

export const EVENTINSTANCES_GET_BY_EVENTID = gql`
  query getEventInstancesById($eventId: ObjectId!) {
    eventInstances(query: { event: { _id: $eventId } }) {
      _id
      creationDate
      event {
        _id
        creationDate
        currency
        date_end
        date_start
        descr_long
        descr_short
        forIndividuals
        forTeams
        forUnderage
        hasOnlineQualifier
        name
        participantLimit
        participationFee
        pic_logo_url
        sports
        type
        noAskForPlatformFee
        eventIsVisible

        organization {
          _id
          address1
          address2
          city
          country
          createdBy
          creationDate
          email
          logo_url
          name
          state
          stripeAccountId
          stripeChargesEnabled
          taxId
          website
          zip
        }
      }
      registrationDeadline
      startDate
      endDate
      description
      organizationId
      type
      isOpenRegistration
      name
      maxParticipants
      registrationFee
      isVirtualEvent
      divisions {
        _id
        name
        description
        maxParticipants
        forIndividuals
        forTeams
        forUnderage
        teamSize
        minAge
        maxAge
        registrationFee
        advancingAthletesNumber
        advancingAthletesPercentage
        assignedJudges
      }
      location {
        address1
        address2
        zip
        city
        country
        state
      }
    }
  }
`;
