import React, { useEffect, useState } from 'react';

import { Card, Typography } from '@mui/material';
import ArgonBox from 'components/ArgonBox';
import BaseLayout from './BaseLayout';
import EventCard from './EventCard';
// import Table from "./Table";

import { useLazyQuery, gql, useQuery } from '@apollo/client';
import { EVENTS_GET_ALL } from 'GraphQL/Events/queries';
import { Box } from '@mui/system';
import { PuffLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import ArgonButton from 'components/ArgonButton';
import { useNavigate } from 'react-router-dom';
import { getSession } from 'services/cognito/cognitoAccount';

const EventList = (props) => {
  const navigate = useNavigate();
  const [organizationId, setOrganizationId] = useState(null);
  const [allowedEvents, setAllowedEvents] = useState([]);

  const [fetchEvents] = useLazyQuery(EVENTS_GET_ALL, {
    onCompleted: (data) => {
      console.log('Events fetched:', data);
    }
  });

  const { loading, error, data } = useQuery(EVENTS_GET_ALL, {
    variables: {
      data: {
        _id_in: allowedEvents
      }
    },
    onCompleted: (data) => {
      console.log('Events fetched:', data);
    }
  });

  useEffect(() => {
    getSession()
      .then(async (session) => {
        const groups = session.idToken.payload['cognito:groups'];
        if (!groups) {
          // navigate('/auth/login');
        }
        const eventAdminGroups = groups.filter((group) =>
          group.startsWith('event-admin')
        );

        const associatedEvents = eventAdminGroups.map(
          (group) => group.split('-')[2]
        );
        console.log('Associated events:', associatedEvents);

        // now get Events from database
        setAllowedEvents(associatedEvents);

        // await fetchEvents({
        //   variables: {
        //     data: {
        //       _id_in: allowedEvents
        //     }
        //   }
        // });
      })

      .catch((error) => {
        console.error('Error fetching Auth token:', error);
        // TODO: if not logged in, forward to login page
      });
  }, []);

  if (error) {
    console.error(error);
    Swal.fire({
      title: 'Error!',
      text: 'Something went wrong. Please try again.',
      icon: 'error',
      confirmButtonText: 'OK'
    });
  }

  const Loader = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh">
        <PuffLoader color="#36d7b7" />
      </Box>
    );
  };

  if (loading) {
    return <Loader />;
  }

  if (data)
    return (
      <div>
        <BaseLayout title="Events">
          <ArgonBox mt={4} mb={2} display="flex" justifyContent="space-between">
            <ArgonBox mt={4} mb={2}>
              <Typography
                variant="h4"
                ml={3}
                sx={{ fontWeight: 'bold', color: 'text.white' }}>
                Events
              </Typography>
            </ArgonBox>
            <ArgonBox mt={4} mb={2}>
              <ArgonButton
                variant="gradient"
                color="success"
                size="small"
                onClick={() => navigate('/organizer/events/create')}>
                Add New Event
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox mt={4} mb={2}>
            {data.events.map((event) => {
              return (
                <div key={event._id}>
                  <ArgonBox mb={2}>
                    <EventCard if={event._id} event={event} />
                  </ArgonBox>
                </div>
              );
            })}
          </ArgonBox>
        </BaseLayout>
      </div>
    );
};

export default EventList;
