import ArgonAvatar from 'components/ArgonAvatar';
import ArgonBox from 'components/ArgonBox';
import React from 'react';

export function loggedInUserRoutes(userData) {
  return [loggedInUserRoute(userData)];
}

export function loggedInUserRoute(userData) {
  return {
    hideNameAndArrow: true,
    name: userData?.firstName + ' ' + userData?.lastName, //loggedInUser.name,
    // route: '/athlete/dashboard',
    icon: (
      <ArgonAvatar
        bgColor="info"
        src={userData?.pic_url}
        alt={userData?.firstName + ' ' + userData?.lastName}
        variant="rounded"
        size="xs"
        shadow="sm">
        {/* {userData?.firstName + ' ' + userData?.lastName} */}
      </ArgonAvatar>
    ),

    collapse: [
      {
        name: 'My dashboard',
        route: '/athlete/dashboard',
        icon: <ArgonBox component="i" color="info" className="ni ni-planet" />
      },
      {
        name: 'My registrations',
        route: '/athlete/registrations',
        icon: (
          <ArgonBox component="i" color="info" className="ni ni-spaceship" />
        )
      },
      {
        name: 'My orders',
        route: '/athlete/orders',
        icon: (
          <ArgonBox
            component="i"
            color="info"
            className="ni ni-bullet-list-67"
          />
        )
      },
      {
        name: 'My profile',
        route: '/athlete/profile',
        icon: (
          <ArgonBox
            component="i"
            color="info"
            // className='ni ni-settings-gear-65'
            className="ni ni-ui-04"
          />
        )
      },
      {
        name: 'Logout',
        route: '/logout',
        icon: (
          <ArgonBox component="i" color="info" className="ni ni-curved-next" />
        )
      }
    ]
  };
}
