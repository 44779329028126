/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-router components
import { Link } from 'react-router-dom';

// @mui material components
import Collapse from '@mui/material/Collapse';
import MuiLink from '@mui/material/Link';
import Icon from '@mui/material/Icon';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

// Argon Dashboard 2 PRO MUI exampless
import DefaultNavbarDropdown from 'examples/Navbars/DefaultNavbar/DefaultNavbarDropdown';

// Argon Dashboard 2 PRO React contexts
import { useArgonController } from 'context';
import { loggedInUserRoutes } from './loggedInUserRoutes';

function DefaultNavbarMobile({ routes, userData, open }) {
  const [collapse, setCollapse] = useState('');
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const handleSetCollapse = (name) =>
    collapse === name ? setCollapse(false) : setCollapse(name);

  // console.log(
  //   'loggedInUserRoutes(userData): ',
  //   loggedInUserRoutes(userData).pop()
  // );

  // routes.push({ ...loggedInUserRoutes(userData).pop() });
  // console.log('Aggreagated mobile navbar routes: ', routes);

  const renderNavbarItems = routes.map(
    ({
      name,
      icon,
      collapse: routeCollapses,
      href,
      route,
      collapse: navCollapse
    }) => (
      <DefaultNavbarDropdown
        key={name}
        name={name}
        icon={icon}
        collapseStatus={name === collapse}
        onClick={() => handleSetCollapse(name)}
        href={href}
        route={route}
        collapse={Boolean(navCollapse)}>
        <ArgonBox
          sx={{ height: '15rem', maxHeight: '15rem', overflowY: 'scroll' }}>
          {routeCollapses &&
            routeCollapses.map((item) => (
              <ArgonBox key={item.name} px={item.icon ? 1 : 2}>
                {item.collapse ? (
                  <>
                    <ArgonBox
                      width="100%"
                      display="flex"
                      alignItems="center"
                      p={1}>
                      {item.icon && (
                        <ArgonBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="1.5rem"
                          height="1.5rem"
                          borderRadius="md"
                          color="text"
                          mr={1}
                          fontSize="1rem"
                          lineHeight={1}>
                          {typeof item.icon === 'string' ? (
                            <Icon>{item.icon}</Icon>
                          ) : (
                            item.icon
                          )}
                        </ArgonBox>
                      )}
                      <ArgonTypography
                        display="block"
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize">
                        {item.name}
                      </ArgonTypography>
                    </ArgonBox>
                    {item.collapse.map((el, index) => (
                      <ArgonTypography
                        key={el.name}
                        component={el.route ? Link : MuiLink}
                        to={el.route ? el.route : ''}
                        href={el.href ? el.href : ''}
                        target={el.href ? '_blank' : ''}
                        rel={el.href ? 'noreferrer' : 'noreferrer'}
                        minWidth="11.25rem"
                        display="block"
                        variant="button"
                        color="text"
                        textTransform="capitalize"
                        fontWeight="regular"
                        py={0.625}
                        px={item.icon ? 5 : 2}
                        mb={index === item.collapse.length - 1 ? 2 : 0}
                        sx={({
                          palette: { white, grey, dark },
                          borders: { borderRadius },
                          functions: { rgba }
                        }) => ({
                          borderRadius: borderRadius.md,
                          cursor: 'pointer',
                          transition: 'all 300ms linear',

                          '&:hover': {
                            backgroundColor: rgba(
                              grey[200],
                              darkMode ? 0.1 : 1
                            ),
                            color: darkMode ? white.main : dark.main
                          }
                        })}>
                        {el.name}
                      </ArgonTypography>
                    ))}
                  </>
                ) : (
                  <ArgonBox
                    key={item.key}
                    display="flex"
                    component={item.route ? Link : MuiLink}
                    to={item.route ? item.route : ''}
                    href={item.href ? item.href : ''}
                    target={item.href ? '_blank' : ''}
                    rel={item.href ? 'noreferrer' : 'noreferrer'}
                    sx={({
                      palette: { white, grey, dark },
                      borders: { borderRadius },
                      functions: { rgba }
                    }) => ({
                      borderRadius: borderRadius.md,
                      cursor: 'pointer',
                      transition: 'all 300ms linear',
                      py: 1,
                      px: 1.625,

                      '&:hover': {
                        backgroundColor: rgba(grey[200], darkMode ? 0.1 : 1),
                        color: darkMode ? white.main : dark.main
                      }
                    })}>
                    <ArgonBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="1.5rem"
                      height="1.5rem"
                      borderRadius="md"
                      color="text"
                      mr={1}
                      fontSize="1rem"
                      lineHeight={1}>
                      {typeof item.icon === 'string' ? (
                        <Icon>{item.icon}</Icon>
                      ) : (
                        item.icon
                      )}
                    </ArgonBox>
                    <ArgonBox>
                      <ArgonTypography
                        display="block"
                        variant="button"
                        fontWeight={!item.description ? 'regular' : 'bold'}
                        mt={!item.description ? 0.25 : 0}
                        textTransform="capitalize">
                        {item.name || '&nbsp'}
                      </ArgonTypography>
                      {item.description && (
                        <ArgonTypography
                          display="block"
                          variant="button"
                          color="text"
                          fontWeight="regular"
                          sx={{ transition: 'all 300ms linear' }}>
                          {item.description}
                        </ArgonTypography>
                      )}
                    </ArgonBox>
                  </ArgonBox>
                )}
              </ArgonBox>
            ))}
        </ArgonBox>
      </DefaultNavbarDropdown>
    )
  );

  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <ArgonBox width="calc(100% + 1.625rem)" my={2} ml={-2}>
        {renderNavbarItems}
      </ArgonBox>
    </Collapse>
  );
}

// Typechecking props for the DefaultNavbarMobile
DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
};

export default DefaultNavbarMobile;
