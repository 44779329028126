import React, { useEffect, useState } from 'react';

import BasicLayout from 'examples/LayoutContainers/PageLayout';

// Pricing page components
import Header from 'Routes/LandingPage/LayoutComponents/Header';
import Footer from 'Routes/LandingPage/LayoutComponents/Footer';

import { PuffLoader } from 'react-spinners';
import ArgonBox from 'components/ArgonBox';
import { Card, Container, Grid, IconButton, Typography } from '@mui/material';
import { getShoppingCartItems } from 'services/shoppingCart';

import { useTranslation } from 'react-i18next';

import { ItemRow } from './ItemRow';
import { useLazyQuery } from '@apollo/client';
import { REGISTRATION_GET_BY_ID } from 'GraphQL/Registrations/queries';
import ArgonButton from 'components/ArgonButton';
import { createNewOrder } from 'services/restApi';
import ItemsPerOrganization from './ItemsPerOrganization';
import ArgonTypography from 'components/ArgonTypography';
import PageHeadline from 'Layout/PageLayout/PageHeadline';

function CheckoutPage() {
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState(getShoppingCartItems());

  const handleShoppingCartUpdate = (event) => {
    setItems(getShoppingCartItems());
  };

  const organizations = Object.keys(items || []) || [];
  console.log('organizations', organizations);

  organizations.map((organizationId) => {
    console.log('organizationId', organizationId);
    console.log('items[organizationId]', items[organizationId]);
  });

  if (organizations.length === 0)
    return (
      <>
        <BasicLayout>
          <Header />
          <ArgonBox>
            <Container style={{ marginTop: '50px' }}>
              <PageHeadline headline="Checkout" />
              <Card>
                <ArgonBox p={4} mb={4}>
                  <Typography variant="h6">
                    Your shopping cart is empty.
                  </Typography>
                </ArgonBox>
              </Card>
            </Container>
          </ArgonBox>
          <Footer />
        </BasicLayout>
      </>
    );

  return (
    <>
      <BasicLayout>
        <Header />

        <ArgonBox>
          <Container style={{ marginTop: '50px' }}>
            <PageHeadline headline="Checkout" />

            <ArgonBox mb={0}>
              {organizations?.map((organizationId) => (
                <>
                  <ArgonBox mb={4}>
                    <ItemsPerOrganization
                      key={organizationId}
                      shoppingCart={items[organizationId]}
                      organizationId={organizationId}
                      onUpdate={handleShoppingCartUpdate}
                    />
                  </ArgonBox>
                </>
              ))}
            </ArgonBox>
            <ArgonBox ml={3}>
              <Typography
                variant="caption"
                color="textLight"
                fontWeight={'regular'}>
                &#185;: platform fees are non refundable.
              </Typography>
              &nbsp;
              <Typography
                variant="caption"
                color="textLight"
                fontWeight={'regular'}>
                &#178;: The applicable VAT rate depends on your country of
                residence and will be shown during the payment process.
              </Typography>
            </ArgonBox>
            {/* </Card> */}
          </Container>
        </ArgonBox>
        <Footer />
      </BasicLayout>
    </>
  );
}

export default CheckoutPage;
