/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI Base Styles
import colors from 'assets/theme/base/colors';

const { info, dark } = colors;

const globals = {
  html: {
    scrollBehavior: 'smooth'
  },
  body: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
    // backgroundColor: `${dark.main} !important`,
  },
  '*, *::before, *::after': {
    margin: 0,
    padding: 0
  },
  'a, a:link, a:visited': {
    textDecoration: 'none !important'
  },
  'a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited': {
    color: `${dark.main} !important`,
    transition: 'color 150ms ease-in !important'
  },
  'a.link:hover, .link:hover, a.link:focus, .link:focus': {
    color: `${info.main} !important`
  },
  hr: {
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0
  },

  p: {
    fontSize: '1rem',
    lineHeight: '1.3rem'
  }
};

export default globals;
