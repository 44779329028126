import ArgonBox from 'components/ArgonBox';
import React from 'react';
import { PuffLoader } from 'react-spinners';

export default function Loader({ minHeight = '15vh' }) {
  return (
    // <ArgonBox
    //   style={{
    //     flexGrow: 1,
    //   }}
    // >
    <ArgonBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight={minHeight}>
      <PuffLoader color="#36d7b7" />
      {/* </ArgonBox> */}
    </ArgonBox>
  );
}
