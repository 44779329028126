import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import { Collapse, Divider, Grid, Icon, Switch, Tooltip } from '@mui/material';
import ArgonTypography from 'components/ArgonTypography';
import AutoCompleteTagsCreate from 'components/AutoCompleteTagsCreate.js';
import FormField from 'layouts/pages/account/components/FormField';
import { useArgonController } from 'context';
import { useMutation } from '@apollo/client';
import { EVENT_DIVISION_CREATE } from 'GraphQL/Events/mutations';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { EVENT_DIVISION_UPDATE } from 'GraphQL/Events/mutations';
import { EVENT_GET_BY_ID } from 'GraphQL/Events/queries';

// const Dialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

function BootstrapDialogTitle(props) {
  const { eventId, children, onClose, ...other } = props;

  console.log('EventId: ', eventId);

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  division: PropTypes.object,
};

export default function CreateDivisionDialog({
  division,
  eventId,
  currency,
  onClose,
}) {
  const [open, setOpen] = React.useState(false);

  const [gqlCreateDivision] = useMutation(EVENT_DIVISION_CREATE, {
    refetchQueries: [
      {
        query: EVENT_GET_BY_ID,
        variables: { eventId: eventId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [gqlUpdateDivision] = useMutation(EVENT_DIVISION_UPDATE, {
    refetchQueries: [
      {
        query: EVENT_GET_BY_ID,
        variables: { eventId: eventId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [controller] = useArgonController();
  const { darkMode } = controller;

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    // description: Yup.string().required("Required"),

    // onlineQualifierRegistrationFee: Yup.number().test(
    //   "is-decimal",
    //   "invalid decimal",
    //   (value) => (value + "").match(/^\d*\.{1}\d*$/)
    // ),
    // eventRegistrationFee: Yup.number().test(
    //   "is-decimal",
    //   "invalid decimal",
    //   (value) => (value + "").match(/^\d*\.{1}\d*$/)
    // ),

    minAge: Yup.number().required().positive().integer(),
    maxAge: Yup.number().required().positive().integer(),
    maxParticipants: Yup.number().required().positive().integer(),
    teamSize: Yup.number().required().positive().integer(),

    forIndividuals: Yup.boolean().required('Required'),
    forTeams: Yup.boolean().required('Required'),
    forUnderage: Yup.boolean().required('Required'),
  });

  // console.log("Division details: ", division);

  const insertOrUpdateDivision = async (eventId, values) => {
    const divisionObject = {
      data: {
        eventId: eventId,
        division: {
          ...values,
          // name: values?.name,
          // description: values?.description,
          // maxParticipants: values?.maxParticipants,
          // forIndividuals: values?.forIndividuals,
          // forTeams: values?.forTeams,
          // forUnderage: values?.forKids,
          // teamSize: values?.teamSize,
          // minAge: values?.minAge,
          // maxAge: values?.maxAge,
          // onlineQualifierRegistrationFee: values?.onlineQualifierRegistrationFee,
          // eventRegistrationFee: values?.eventRegistrationFee,
        },
      },
    };
    console.log('Insering / updating: ', divisionObject);

    if (division?._id) {
      // if id exists, update division
      console.log('Updating...');
      gqlUpdateDivision({ variables: divisionObject })
        .then(() => {
          Swal.fire({
            title: 'Good job!',
            text: 'Your division has been updated!',
            icon: 'success',
          });
          onClose && onClose();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire({
            title: 'Whoops!',
            text: 'Something went wrong. Please try again.',
            icon: 'error',
          });
        });
    } else {
      console.log('Inserting');
      gqlCreateDivision({ variables: divisionObject })
        .then(() => {
          Swal.fire({
            title: 'Good job!',
            text: 'Your division has been created!',
            icon: 'success',
          });
          onClose && onClose();
        })
        .catch((err) => {
          console.error(err);
          Swal.fire({
            title: 'Whoops!',
            text: 'Something went wrong. Please try again.',
            icon: 'error',
          });
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      // eventId: eventId,
      _id: division?._id || undefined,
      name: division?.name || '',
      description: division?.description || '',
      maxParticipants: division?.maxParticipants || '',
      forIndividuals: division?.forIndividuals || false,
      forTeams: division?.forTeams || false,
      forUnderage: division?.forUnderage || false,
      teamSize: division?.teamSize || 2,
      minAge: division?.minAge || 18,
      maxAge: division?.maxAge || 99,
      onlineQualifierRegistrationFee:
        division?.onlineQualifierRegistrationFee || 0,
      eventRegistrationFee: division?.eventRegistrationFee || 0,

      // pic_logo_url: String,
      // hasOnlineQualifier: Boolean,
      // participantLimit: Number,
      // participationFee: Number,
      // onlineQualifierEndDate: Date,
      // onlineQualifierStartDate: Date,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));

      await insertOrUpdateDivision(eventId, values);
    },
  });

  const handleCancelButton = () => {
    console.log('handleCancelButton');
    onClose && onClose();
  };

  const handleSwitchChange = (event) => {
    console.log('handleSwitchChange', event.target.name, event.target.checked);
    formik.setFieldValue(event.target.name, event.target.checked);
  };

  return (
    <div>
      <DialogTitle id='customized-dialog-title' onClose={handleCancelButton}>
        Create New Division
      </DialogTitle>
      <DialogContent dividers>
        <ArgonBox
          component='li'
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
          borderRadius='lg'
          pl={0}
          pr={0}
          mb={1}
          mt={2}
          // sx={({ palette: { grey, background } }) => ({
          //   backgroundColor: darkMode ? background.default : grey[100],
          // })}
        >
          <ArgonBox width='100%' display='flex' flexDirection='column'>
            <Grid container spacing={0} mt={{ xs: 1 }}>
              <Grid item xs={12}>
                <ArgonBox mb={2}>
                  <FormField
                    id='name'
                    name='name'
                    size='small'
                    label='Division name'
                    placeholder=''
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </ArgonBox>
              </Grid>
              <Grid item xs={12}>
                <FormField
                  id='description'
                  name='description'
                  size='small'
                  label='Description of division, e.g. with required movement capabilities, etc.'
                  multiline
                  rows={4}
                  placeholder=''
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
              <ArgonBox mb={1} lineHeight={0}>
                <ArgonTypography variant="caption" color="text">
                  {division.forTeams
                    ? "Max # of teams"
                    : "Max # of participants"}

                  <ArgonTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {formik.values?.maxParticipants}
                  </ArgonTypography>
                </ArgonTypography>
              </ArgonBox>
            </Grid> */}

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant='caption' fontWeight='medium'>
                      For individuals:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox lineHeight={0} mx={2}>
                      <ArgonTypography variant='caption' color='text'>
                        {formik.values?.forIndividuals ? 'Yes' : 'No'}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mr={1} alignItems='center'>
                      <Switch
                        name='forIndividuals'
                        checked={formik.values?.forIndividuals}
                        onChange={handleSwitchChange}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant='caption'
                      // color="text"
                      fontWeight='medium'
                    >
                      For underage:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox lineHeight={0} mx={2}>
                      <ArgonTypography
                        variant='caption'
                        color='text'
                        // fontWeight="medium"
                      >
                        {formik.values?.forUnderage ? 'Yes' : 'No'}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mr={1} alignItems='center'>
                      <Switch
                        name='forUnderage'
                        checked={formik.values?.forUnderage}
                        onChange={handleSwitchChange}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant='caption' fontWeight='medium'>
                      For teams:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox lineHeight={0} mx={2}>
                      <ArgonTypography
                        variant='caption'
                        color='text'
                        // fontWeight="medium"
                      >
                        {formik.values?.forTeams ? 'Yes' : 'No'}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mr={1} alignItems='center'>
                      <Switch
                        name='forTeams'
                        checked={formik.values?.forTeams}
                        onChange={handleSwitchChange}
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Collapse in={formik.values?.forTeams}>
                  <ArgonBox
                    display='flex'
                    alignItems='center'
                    width={{ xs: '100%' }}
                    justifyContent='space-between'
                    // sx={({ palette: { grey, background } }) => ({
                    //   backgroundColor: darkMode
                    //     ? background.default
                    //     : grey[100],
                    // })}
                  >
                    {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                    <ArgonBox
                    // lineHeight={1.4}
                    >
                      <ArgonTypography variant='caption' fontWeight='medium'>
                        Teamsize:
                      </ArgonTypography>
                    </ArgonBox>

                    <ArgonBox
                      display='flex'
                      // justifyContent="flex-end"
                      alignItems='center'
                      width={{ xs: '100%', sm: 'auto' }}
                      mt={{ xs: 1, sm: 0 }}
                    >
                      <ArgonBox mr={1} alignItems='center'>
                        <FormField
                          id='teamSize'
                          name='teamSize'
                          value={formik.values?.teamSize}
                          style={{ maxWidth: '100px' }}
                          size='small'
                          startAdornment={
                            <ArgonBox mr={1} ml={0}>
                              #
                            </ArgonBox>
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.teamSize &&
                            Boolean(formik.errors.teamSize)
                          }
                          helperText={
                            formik.touched.teamSize && formik.errors.teamSize
                          }
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </Collapse>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant='caption' fontWeight='medium'>
                      Max # of participants:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox mr={1} alignItems='center'>
                      <FormField
                        id='maxParticipants'
                        name='maxParticipants'
                        value={formik.values?.maxParticipants}
                        style={{ maxWidth: '100px' }}
                        size='small'
                        startAdornment={
                          <ArgonBox mr={1} ml={0}>
                            #
                          </ArgonBox>
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.maxParticipants &&
                          Boolean(formik.errors.maxParticipants)
                        }
                        helperText={
                          formik.touched.maxParticipants &&
                          formik.errors.maxParticipants
                        }
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography variant='caption' fontWeight='medium'>
                      Online qualifier registration fee:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox mr={1} alignItems='center'>
                      <Typography variant='caption'>
                        <FormField
                          id='onlineQualifierRegistrationFee'
                          name='onlineQualifierRegistrationFee'
                          value={formik.values?.onlineQualifierRegistrationFee}
                          style={{ maxWidth: '100px' }}
                          size='small'
                          startAdornment={
                            <ArgonBox mr={1} ml={0}>
                              EUR
                            </ArgonBox>
                          }
                          onChange={formik.handleChange}
                          error={
                            formik.touched.onlineQualifierRegistrationFee &&
                            Boolean(
                              formik.errors.onlineQualifierRegistrationFee
                            )
                          }
                          helperText={
                            formik.touched.onlineQualifierRegistrationFee &&
                            formik.errors.onlineQualifierRegistrationFee
                          }
                        />
                      </Typography>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant='caption'
                      // color="text"
                      fontWeight='medium'
                    >
                      Final registration fee:
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox mr={1} alignItems='center'>
                      <FormField
                        id='eventRegistrationFee'
                        name='eventRegistrationFee'
                        value={formik.values?.eventRegistrationFee}
                        style={{ maxWidth: '100px' }}
                        startAdornment={
                          <ArgonBox mr={1} ml={0}>
                            EUR
                          </ArgonBox>
                        }
                        size='small'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.eventRegistrationFee &&
                          Boolean(formik.errors.eventRegistrationFee)
                        }
                        helperText={
                          formik.touched.eventRegistrationFee &&
                          formik.errors.eventRegistrationFee
                        }
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant='caption'
                      // color="text"
                      fontWeight='medium'
                    >
                      Minimum age of competitior (years):
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox mr={1} alignItems='center'>
                      <FormField
                        id='minAge'
                        name='minAge'
                        value={formik.values?.minAge}
                        style={{ maxWidth: '100px' }}
                        size='small'
                        startAdornment={
                          <ArgonBox mr={1} ml={0}>
                            Yrs
                          </ArgonBox>
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.minAge && Boolean(formik.errors.minAge)
                        }
                        helperText={
                          formik.touched.minAge && formik.errors.minAge
                        }
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ArgonBox
                  display='flex'
                  alignItems='center'
                  width={{ xs: '100%' }}
                  justifyContent='space-between'
                >
                  {/* <ArgonAvatar src={logoSpotify} alt="Slack logo" variant="rounded" /> */}
                  <ArgonBox
                  // lineHeight={1.4}
                  >
                    <ArgonTypography
                      variant='caption'
                      // color="text"
                      fontWeight='medium'
                    >
                      Maximum age of competitor (years):
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox
                    display='flex'
                    // justifyContent="flex-end"
                    alignItems='center'
                    width={{ xs: '100%', sm: 'auto' }}
                    mt={{ xs: 1, sm: 0 }}
                  >
                    <ArgonBox mr={1} alignItems='center'>
                      <FormField
                        id='maxAge'
                        name='maxAge'
                        value={formik.values?.maxAge}
                        style={{ maxWidth: '100px' }}
                        size='small'
                        startAdornment={
                          <ArgonBox mr={1} ml={0}>
                            Yrs
                          </ArgonBox>
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.maxAge && Boolean(formik.errors.maxAge)
                        }
                        helperText={
                          formik.touched.maxAge && formik.errors.maxAge
                        }
                      />
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
            </Grid>
          </ArgonBox>
        </ArgonBox>
      </DialogContent>
      <DialogActions>
        <ArgonBox
          width={{ xs: '100%' }}
          justifyContent='space-between'
          display='flex'
        >
          <ArgonButton
            variant='outlined'
            color='secondary'
            size='small'
            onClick={handleCancelButton}
          >
            Cancel1
          </ArgonButton>
          <ArgonButton
            variant='gradient'
            color='success'
            size='small'
            onClick={formik.submitForm}
          >
            {division?._id ? 'Update Division' : 'Create Division'}
          </ArgonButton>
        </ArgonBox>
        {/* <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus color="success" onClick={handleClose}>
          Save changes
        </Button> */}
      </DialogActions>
    </div>
  );
}
