/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from 'react';

// // @mui material components
// import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

// Settings page components
import BaseLayout from '../BaseLayout';

import { useParams, useSearchParams } from 'react-router-dom';

// import { PuffLoader } from "react-spinners";

import EventSetup from './EventSetup';
// import { Grow, Fade } from "@mui/material";
import Grow from '@mui/material/Grow';

import { useQuery } from '@apollo/client';
import { EVENT_GET_BY_ID } from 'GraphQL/Events/queries';
// import Workouts from './EventInstances/Workouts';
import EventInstancePage from './EventInstances/Setup';

function EventDetails() {
  const { eventId } = useParams();

  let [searchParams] = useSearchParams();
  console.log('Search Params: ', searchParams);
  // const navigate = useNavigate();
  // const location = useLocation();

  const [selectedEventInstanceId, setSelectedEventInstanceId] =
    React.useState(undefined);

  const [isNewRecord, setIsNewRecord] = React.useState(
    searchParams?.get('new') === 'true'
    // Boolean(eventId == 'create')
  );

  // const { loading, error, data } = useQuery(EVENT_GET_BY_ID, {
  //   fetchPolicy: 'network-only',
  //   variables: { eventId: eventId },
  //   onCompleted: async (data) => {
  //     console.log('Event Base: Event data received from Database: ', data);
  //   }
  // });

  // if (error) console.error('Error loading Event: ', error);

  // if (gqlEvent.data || id === undefined) {
  return (
    <BaseLayout eventId={eventId}>
      <ArgonBox mt={4} mb={4}>
        {!selectedEventInstanceId ? (
          <EventSetup eventId={eventId} isNewRecord={isNewRecord} />
        ) : (
          <EventInstancePage
            key={selectedEventInstanceId}
            eventInstanceId={selectedEventInstanceId}
            eventId={eventId}
          />
        )}
      </ArgonBox>
    </BaseLayout>
    // </BaseLayout>
  );
  // }
}

export default EventDetails;
