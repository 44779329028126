const { gql } = require("@apollo/client");

export const USERS_GET_ALL = gql`
  query {
    users {
      email
      firstName
      lastName
      dateOfBirth
      gender
      address1
      address2
      city
      zip
      country
      pic_url
    }
  }
`;

export const USER_GET_BY_ID = gql`
  query getUserById($userId: ObjectId!) {
    user(query: { _id: $userId }) {
      _id
      email
      firstName
      lastName
      dateOfBirth
      gender
      address1
      address2
      city
      zip
      country
      pic_url
    }
  }
`;
