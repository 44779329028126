import { gql } from '@apollo/client';

export const EVENT_INSERT = gql`
  mutation InsertEvent($data: EventInsertInput!) {
    insertOneEvent(input: $data) {
      _id
      organization {
        _id
        address1
        address2
        city
        country
        createdBy
        creationDate
        email
        logo_url
        name
        state
        stripeAccountId
        stripeChargesEnabled
        taxId
        website
        zip
      }
      creationDate
      currency
      date_start
      date_end
      descr_long
      descr_short
      forIndividuals
      forUnderage
      forTeams
      hasOnlineQualifier
      name
      sports
      participantLimit
      participationFee
      pic_logo_url
      type
      subDivisionOptions {
        _id
        name
        description
        minAge
        maxAge
      }
      divisions {
        _id
        name
        description
        maxParticipants
        forIndividuals
        forTeams
        forUnderage
        teamSize
        minAge
        maxAge
        registrationFee
      }
      noAskForPlatformFee
      eventIsVisible
    }
  }
`;

export const EVENT_UPDATE = gql`
  mutation UpdateEvent($eventId: ObjectId!, $data: EventUpdateInput!) {
    updateOneEvent(query: { _id: $eventId }, set: $data) {
      _id
      organization {
        _id
        address1
        address2
        city
        country
        createdBy
        creationDate
        email
        logo_url
        name
        state
        stripeAccountId
        stripeChargesEnabled
        taxId
        website
        zip
      }
      creationDate
      currency
      date_start
      date_end
      descr_long
      descr_short
      forIndividuals
      forUnderage
      forTeams
      hasOnlineQualifier
      name
      sports
      participantLimit
      participationFee
      pic_logo_url
      type
      subDivisionOptions {
        _id
        name
        description
        minAge
        maxAge
      }
      divisions {
        _id
        name
        description
        maxParticipants
        forIndividuals
        forTeams
        forUnderage
        teamSize
        minAge
        maxAge
        registrationFee
      }
      eventIsVisible
      noAskForPlatformFee
    }
  }
`;

export const EVENT_DIVISION_CREATE = gql`
  mutation eventDivisionCreate($data: EventDivisionCreateInput) {
    EventDivisionCreate(input: $data) {
      _id
      name
      description
      maxParticipants
      forIndividuals
      forTeams
      forUnderage
      teamSize
      minAge
      maxAge
      registrationFee
    }
  }
`;

export const EVENT_DIVISION_UPDATE = gql`
  mutation updateEventDivision($data: EventDivisionUpdateInput_!) {
    EventDivisionUpdate(input: $data) {
      eventId
      division {
        name
        description
        maxParticipants
        forIndividuals
        forTeams
        forUnderage
        teamSize
        minAge
        maxAge
        registrationFee
      }
    }
  }
`;

export const EVENT_DIVISION_DELETE = gql`
  mutation deleteDivision($data: EventDivisionDeleteInput!) {
    EventDivisionDelete(input: $data) {
      status
    }
  }
`;
