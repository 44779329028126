/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';

// Argon Dashboard 2 PRO MUI base styles
import breakpoints from 'assets/theme/base/breakpoints';

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from 'Layout/DashboardLayout';
import DashboardNavbar from 'Layout/DashboardNavbar';
import Header from 'Routes/Organizer/LayoutComponents/Header';
import Footer from 'Routes/Organizer/LayoutComponents/Footer';
import { EVENTINSTANCES_GET_BY_EVENTID } from 'GraphQL/EventInstances/queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import ArgonButton from 'components/ArgonButton';
import colors from 'assets/theme/base/colors';
import ArgonTypography from 'components/ArgonTypography';
import { useArgonController } from 'context';
import { Card, Stack } from '@mui/material';
import selectData from 'options/selectData';

import NoImagePlaceholder from 'assets/images/placeholder/no_image.png';

import SettingsIcon from '@mui/icons-material/Settings';
import TuneIcon from '@mui/icons-material/Tune';
import AddIcon from '@mui/icons-material/Add';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

function BaseLayout({
  stickyNavbar,
  children,
  onTabChange,
  onEventInstanceChange,
  event
}) {
  // const theme = useTheme();
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState('setup');
  const [selectedEventInstanceId, setSelectedEventInstanceId] = useState(null);

  const [getEventInstanceData, { data, loading, error }] = useLazyQuery(
    EVENTINSTANCES_GET_BY_EVENTID,
    {
      onCompleted: async (data) => {
        console.log(
          'Event Base Layout: Event Instances received from Database: ',
          data
        );
      },
      onError: (error) => {
        console.error(
          'Event Base Layout: Error getting event instances from Database: ',
          error
        );
      }
    }
  );

  useEffect(() => {
    if (event?._id) {
      console.log('Event Base Layout: Getting event instances from Database');
      getEventInstanceData({ variables: { eventId: event?._id } });
    }
  }, [event]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    console.log('New Tab Value', newValue);
    onTabChange && onTabChange(newValue);
  };

  const handleEventInstanceSelected = (eventInstanceId) => {
    setSelectedEventInstanceId(eventInstanceId);
    onEventInstanceChange && onEventInstanceChange(eventInstanceId);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar
      // absolute={!stickyNavbar}
      // isMini
      /> */}
      <ArgonBox

      // mt={stickyNavbar ? 3 : 10}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <ArgonBox mb={1}>
              {/* <Header
                key={event?.pic_logo_url + event?.name + event?.descr_short}
                visible={true}
                title={event?.name || 'New Event'}
                subTitle={event?.descr_short}
                logoUrl={event?.pic_logo_url}
              /> */}

              <Card
                id="profile"
                onClick={() => handleEventInstanceSelected(null)}>
                <ArgonBox p={2}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item>
                      <ArgonBox
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <ArgonBox
                          component="img"
                          src={event?.pic_logo_url || NoImagePlaceholder}
                          shadow="md"
                          borderRadius="md"
                          width="80px"
                          height="80px"
                          style={{
                            objectFit: 'cover'
                          }}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item>
                      <ArgonBox height="100%" mt={0.5} lineHeight={1}>
                        <ArgonTypography variant="h5" fontWeight="medium">
                          {event?.name || 'New Event'}
                        </ArgonTypography>
                        <ArgonTypography
                          variant="button"
                          color="text"
                          fontWeight="medium">
                          {event?.descr_short}
                        </ArgonTypography>
                      </ArgonBox>
                    </Grid>
                    {/* <Grid item xs={12} md={6} lg={3} sx={{ ml: 'auto' }}> */}
                    {/* <ArgonBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <ArgonTypography variant="caption" fontWeight="regular">
                Switch to {visible ? "invisible" : "visible"}
              </ArgonTypography>
              <ArgonBox mx={1}>
                <Switch checked={visible} onChange={handleSetVisible} />
              </ArgonBox>
            </ArgonBox> */}
                    {/* </Grid> */}
                  </Grid>
                </ArgonBox>
              </Card>
            </ArgonBox>
          </Grid>
          {/* <Grid item xs={12}>
            {/* <ArgonBox mb={4}> */}
          {/* <Grid
                container
                // rowGap={0}
                // columnGap={0}
                rowSpacing={2}
                columnSpacing={2}
              > */}
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <Card
              sx={{
                cursor: 'pointer',
                boxShadow:
                  !selectedEventInstanceId &&
                  'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                '&:hover': {
                  // opacity: 0.8,
                  transition: 'all 0.5s ease',
                  // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                  boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                  transform: 'translate(0px, -3px)'
                  // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 4px 4px 0px',
                  // filter: 'brightness(50%)',
                  // '*': 'inherit',
                }
              }}
              onClick={() => handleEventInstanceSelected(null)}>
              <ArgonBox p={1} pl={2} display="flex" alignItems="center">
                <TuneIcon color="dark" />
                &nbsp;
                <ArgonTypography variant="button" noWrap>
                  {/* {event?.name || 'New Event'} */}
                  Event Setup
                </ArgonTypography>
              </ArgonBox>
            </Card>
            {/* </ArgonBox> */}
          </Grid>

          {data?.eventInstances?.map((eventInstance) => {
            return (
              <Grid item xs={12} sm={6} md={3} xl={3} key={eventInstance?._id}>
                {/* <ArgonBox style={{ minWidth: '250px' }}> */}
                <Card
                  sx={{
                    cursor: 'pointer',
                    boxShadow:
                      selectedEventInstanceId === eventInstance?._id &&
                      'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                    '&:hover': {
                      // opacity: 0.8,
                      transition: 'all 0.5s ease',
                      // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                      boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                      transform: 'translate(0px, -3px)'
                      // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 4px 4px 0px',
                      // filter: 'brightness(50%)',
                      // '*': 'inherit',
                    }
                  }}
                  onClick={() =>
                    handleEventInstanceSelected(eventInstance?._id)
                  }>
                  <ArgonBox p={1} pl={2} display="flex" alignItems="center">
                    <RocketLaunchIcon color="dark" />
                    &nbsp;
                    <ArgonTypography variant="button" noWrap>
                      {eventInstance?.name ||
                        selectData.eventInstanceTypes.find(
                          (type) => type.value === eventInstance?.type
                        )?.label}
                    </ArgonTypography>
                  </ArgonBox>
                </Card>
                {/* </ArgonBox> */}
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6} md={3} xl={3}>
            {/* <ArgonBox mb={1} style={{ minWidth: '250px' }}> */}
            <Card
              sx={{
                cursor: 'pointer',
                backgroundColor: 'rgba(3, 102, 214, 0)',

                boxShadow: 0,
                //   selectedEventInstanceId === eventInstance?._id &&
                //   'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                '&:hover': {
                  // opacity: 0.8,

                  transition: 'all 0.5s ease',
                  // boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25)',
                  boxShadow: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 3px',
                  transform: 'translate(0px, -3px)'
                  // boxShadow: 'rgba(17, 12, 46, 0.15) 0px 4px 4px 0px',
                  // filter: 'brightness(50%)',
                  // '*': 'inherit',
                }
              }}
              onClick={() => handleEventInstanceSelected()}>
              <ArgonBox p={1} pl={2} display="flex" alignItems="center">
                <AddIcon color="dark" />
                &nbsp;
                <ArgonTypography variant="button">
                  Add New Event Instance
                </ArgonTypography>
              </ArgonBox>
            </Card>
            {/* </Grid>
              </Grid> */}
            {/* </ArgonBox> */}
          </Grid>
        </Grid>
        {children}
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

// Setting default values for the props of BaseLayout
BaseLayout.defaultProps = {
  stickyNavbar: false
};

// Typechecking props for BaseLayout
BaseLayout.propTypes = {
  stickyNavbar: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onTabChange: PropTypes.func.isRequired
};

export default BaseLayout;
