/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';

// Argon Dashboard 2 PRO MUI example components
import DefaultNavbar from '../DefaultNavbar';

// Argon Dashboard 2 PRO MUI page layout routes
import pageRoutes from 'page.routes';

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from 'context';

// Images
import bgImage from 'assets/images/pricing-header-bg.jpg';
import bgImage2 from 'assets/images/crossfit/thandy-yung-nWTVGAHxHek-unsplash.jpg';
//

import jplAvatar from 'assets/images/team-3.jpg';
import { getSession } from 'services/cognito/cognitoAccount';

function Header({
  tabNames,
  tabValue,
  tabHandler,
  title,
  subtitle,
  navbarLight,
  navbarTransparent,
}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [userName, setUserName] = useState('Jan');
  // const [avatarUrl, setAvatarUrl] = useState(jplAvatar);
  // const [userId, setUserId] = useState('11111');

  // useEffect(() => {
  //   getSession()
  //     .then((session) => {
  //       setIsLoggedIn(true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoggedIn(false);
  //       // navigate("/login", { replace: true });
  //     });
  // }, []);

  return (
    <>
      <DefaultNavbar
        routes={pageRoutes}
        // loggedInUser={
        //   isLoggedIn
        //     ? { name: userName, avatarUrl: avatarUrl, userId: userId }
        //     : {}
        // }
        // action={{
        //   type: "external",
        //   route: "https://creative-tim.com/product/argon-dashboard-pro-react",
        //   label: "Buy Now",
        //   color: "white",
        // }}
        transparent={Boolean(navbarTransparent)}
        light={Boolean(navbarLight)}
      />
      <ArgonBox
        position='absolute'
        height='100vh'
        // height='200px'
        overflow='hidden'
        // overflow='visible'
        pt={12}
        pb={20}
        // sx={({
        //   palette: { gradients },
        //   functions: { linearGradient, rgba },
        // }) => ({
        //   backgroundImage: `${linearGradient(
        //     rgba(gradients.info.main, 0.5),
        //     rgba(gradients.info.state, 0.5)
        //   )}, url(${bgImage2})`,
        //   backgroundSize: 'cover',
        //   // backgroundPosition: "50%",
        //   backgroundAttachment: 'fixed',
        //   width: '100%',
        // })}
      >
        <Grid
          container
          spacing={3}
          justifyContent='center'
          sx={{ textAlign: 'center' }}
        >
          <Grid item xs={10} lg={5}>
            <ArgonBox mt={3} mb={1}>
              <ArgonTypography variant='h3' color='white' fontWeight='bold'>
                {title}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mb={2}>
              <ArgonTypography
                variant='body2'
                color='white'
                fontWeight='regular'
              >
                {subtitle}
              </ArgonTypography>
            </ArgonBox>
            <Grid
              container
              item
              xs={12}
              sm={10}
              md={8}
              lg={7}
              sx={{ mx: 'auto' }}
            >
              {/* <ArgonBox width="100%" mt={6}>
                <AppBar position="static">
                  <Tabs value={tabValue} onChange={tabHandler}>
                    <Tab
                      id="monthly"
                      label={
                        <ArgonBox
                          color={darkMode ? "white" : "dark"}
                          py={0.5}
                          px={2}
                        >
                          Monthly
                        </ArgonBox>
                      }
                    />
                    <Tab
                      id="annual"
                      label={
                        <ArgonBox
                          color={darkMode ? "white" : "dark"}
                          py={0.5}
                          px={2}
                        >
                          Annual
                        </ArgonBox>
                      }
                    />
                  </Tabs>
                </AppBar>
              </ArgonBox> */}
            </Grid>
          </Grid>
        </Grid>
      </ArgonBox>
      <ArgonBox height='8vh' />
    </>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  tabValue: PropTypes.number,
  tabHandler: PropTypes.func,
};

export default Header;
